import { IProduct } from './types'
import __multiple from '../../helpers/multiple_actions/__multiple'
import { Image, Table, Switch, Tag } from "antd";
import { observer } from 'mobx-react-lite'
import i from '../../translations/i'
import React from "react";

export const ProductsList = observer(({ products, pagination, actions, setActive }: {products: IProduct[], pagination: any, actions: any, setActive: any}) => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('categories:picture'),
            dataIndex: 'image',
            key: 'image',
            render: (picture: any) => picture && <Image width={'100px'} src={picture.path} />,
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name',
                render: (el: any, record: any) => <>
                <p>{el}</p>
                <p>{record.params && record.params.map((param: any) => <Tag>{param.name}: {param.values[0].value}</Tag>)}</p>
            </>
        },
        {
            title: i.t('def:vendor'),
            dataIndex: 'vendor_code',
            key: 'vendor_code'
        },
        {
            title: i.t('def:price'),
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: i.t('product:count'),
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: i.t('product:active'),
            key: 'active',
            render: (product: IProduct) => <Switch size="small" checked={product.view > 0} onChange={(e) => setActive(product.id, e)} />
        },
        actions
    ]

    return(
        <>
            <Table
                rowKey="id"
                rowSelection={{
                    onSelect: (record, selected, selectedRows) => {
                        __multiple.set(selectedRows)
                    },
                    onSelectAll: (selected, selectedRows) => {
                        __multiple.set(selectedRows)
                    },
                    type: 'checkbox',
                    selectedRowKeys: __multiple.selected
                }}
                columns={columns}
                dataSource={products}
                pagination={pagination}
            />
        </>
    )
})