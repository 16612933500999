import { Switch, Space, Row, Col, Button } from "antd";
import { __product } from "../front/catalog/product/__product";
import { storeLocal } from "../helpers/stores";
import i from "../translations/i";
import { ErrorByPricingPlan } from "./ErrorByPricingPlan";
import React from "react";

export const SwitchBlock = ({ array }: { array: any[] }) => {

    return(
        <div className="switch_block">
            {array.map((el: any, i: number) => el.block ? (
              <ErrorByPricingPlan
                plan={el.block}
                content={
                    <div className="switch_block_element" key={i}>
                        <Row justify="space-around" align="middle">
                            <Col span={18}>
                                <p className="switch_block_element-title">{el.label}</p>
                                {el.description ? <p>{el.description}</p> : <></>}
                            </Col>
                            <Col span={6} className="right">
                                <Switch
                                  size="small"
                                  onClick={(e) => el.click(e)}
                                  checked={el.value}
                                  disabled={el.disabled ? el.disabled : (storeLocal.get('pricing_plan') < el.block)}
                                />
                            </Col>
                        </Row>
                    </div>
                }
              />) : (
                <div className="switch_block_element" key={i}>
                    <Row justify="space-around" align="middle">
                        <Col span={18}>
                            <p className="switch_block_element-title">{el.label}</p>
                            {el.description ? <p>{el.description}</p> : <></>}
                        </Col>
                        <Col span={6} className="right">
                            <Switch size="small" onClick={(e) => el.click(e)} checked={el.value} disabled={el.disabled ?? false} />
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    )
}