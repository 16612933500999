import { observer } from 'mobx-react-lite'
import controller from '../controllers/user.controller'
import i from '../../../translations/i'
import { Helmet } from 'react-helmet'
import { Row, Col, Button, Input } from 'antd'
import { UserIcon } from '../controllers/user.icons'
import { useEffect } from 'react'
import { config } from '../../../config/app'

export const Login = observer(() => {

    return (
        <>
            <Helmet>
                <title>{i.t('auth:title.login')}</title>
            </Helmet>
            <div className="login-form">
                <form onSubmit={(e) => controller.login(e)}>
                    <Row gutter={[15, 15]}>
                        <Col span={24}>
                            <h1>{i.t('auth:title.login')}</h1>
                        </Col>
                        <Col span={24}>
                            <Row
                              gutter={[15, 15]}
                              className="fixed"
                              justify="space-around"
                            >
                                {controller.error !== '' && (
                                  <Col span={24} className="errors">
                                      <Row align="top">
                                          <Col>{UserIcon('error')}</Col>
                                          <Col>
                                              <p>{controller.error}</p>
                                          </Col>
                                      </Row>
                                  </Col>
                                )}
                                <Col span={24}>
                                    <Input
                                      className={
                                          controller.error !== '' ? 'error' : ''
                                      }
                                      key={'login_form_email'}
                                      type="email"
                                      placeholder={i.t('auth:placeholder.email')}
                                      value={controller.dataLogin.login}
                                      prefix={UserIcon('email')}
                                      onChange={(e) =>
                                        controller.onChangeLogin(
                                          'login',
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                </Col>
                                <Col span={24}>
                                    <Input.Password
                                      className={
                                          controller.error !== '' ? 'error' : ''
                                      }
                                      key={'login_form_password'}
                                      type="password"
                                      placeholder={i.t(
                                        'auth:placeholder.password'
                                      )}
                                      value={controller.dataLogin.password}
                                      prefix={UserIcon('password')}
                                      onChange={(e) =>
                                        controller.onChangeLogin(
                                          'password',
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                </Col>
                                <Col span={24} className="_forgot">
                                    <a href="/forgot">{i.t('auth:link.forgot')}</a>
                                </Col>
                                <Col span={24} className="buttons">
                                    <Row
                                      justify="space-around"
                                      align="middle"
                                      gutter={[10, 10]}
                                    >
                                        <Col span={12} className="right">
                                            <Button
                                              className="button"
                                              key={'login_form_button_submit'}
                                              type="primary"
                                              htmlType="submit"
                                              disabled={controller.error !== ''}
                                            >
                                                {i.t('def:actions.enter')}
                                            </Button>
                                        </Col>
                                        <Col span={12} className="left">
                                            <Button
                                              key={'login_form_button_register'}
                                              type="primary"
                                              href="/register"
                                              className="button register"
                                            >
                                                {i.t('def:actions.register')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </div>
        </>
    )
})

export const LoginSelectProject = observer(() => {

    useEffect(() => {
        controller.getProjectsByManager()
        controller.reload()
    }, [])

    return(
        <>
            <Row gutter={[20, 20]}>
                {controller.projects.length
                    ? <>
                        <Col span={24}>
                            <h1>{i.t('def:Select account')}:</h1>
                        </Col>
                        {controller.projects.map((el: any) => (
                            <Col span={24}>
                                <button className="select_account" onClick={() => controller.changeProject(el.id)}>{el.name} {el.seller ? <></> : <span>{el.bandl}</span>}</button>
                            </Col>
                        ))}
                    </>
                    : <>
                    <Col span={24}>
                        <Row justify="space-around" align="middle" className="wait">
                            <Col span={12}>
                                <h1>{i.t('wait:h1')}!</h1>
                                <h2>{i.t('wait:h2')}</h2>

                                <p className='description'>{i.t('wait:description')}</p>

                                <p className='form-text'>
                                    <span>{i.t('wait:form')}</span>
                                </p>

                                <div className='buttons'>
                                    <Button
                                        type="primary"
                                        className="button"
                                        href={config.formUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i.t('wait:buttonForm')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </>}
            </Row>
        </>
    )
})
