import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import i from '../../../translations/i'
import { notification } from 'antd'

class controller {
  list: any[] = []
  item: any = {
    id: 0,
    title: '',
    categories_ids: []
  }

  constructor() {
    makeAutoObservable(this)
  }

  get(itemID: string | number = 0) {
    if(Number(itemID) > 0) {
      openApi('get', `/calendar/${itemID}`, {})
        .then(result => {
          console.log(result)
          this.item = result.data
        })
    } else {
      openApi('get', `/calendar`)
        .then(result => {
          console.log(result)
          result = result.data
          this.list = result
        })
    }
  }

  set(name: string, value: any) {
    //@ts-ignore
    this.item[name] = value
  }

  save(e: FormEvent) {
    e.preventDefault()

    let data = new FormData()
    data.append('title', `${this.item.title}`)

    this.item.categories_ids.map((el: number) => data.append('categories[]', `${el}`))

    openApi('post', `/calendar/${this.item.id}`, data)
      .then(result => {
        if(this.item.id === 0) window.location.href = `/settings/calendars`
        else {
          this.get(this.item.id)
          notification.success({
            message: i.t('def:success.complete'),
            description: i.t('def:success.edit')
          });
        }
      })
      .catch((error) => console.log(error.response))
  }

  remove(cityID: string) {
    if(window.confirm(i.t('def:confirms.delete'))) {
      openApi('delete', `/calendar/${cityID}`)
        .then(result => {
          this.get()
        })
        .catch((error) => console.log(error.response))
    }
  }
}

export default new controller()