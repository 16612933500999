import { observer } from 'mobx-react-lite'
import __select from './__select.controller'
import { Row, Col, Select, Button, Input, Space, Table, Image, Tag } from "antd";
import React, { useEffect } from 'react'
import i from '../../translations/i'
import { CustomProductListsLayout } from '../../front/catalog/custom_product_lists/CustomProductListsLayout'
import { __group } from '../../front/catalog/groups/__group'
import { UpOutlined, DownOutlined } from "@ant-design/icons";

export const SelectEntity = observer(({ type, element, none = true, elements = [], item_element = null, types = [] }: any) => {

    useEffect(() => {
        if(!none) __select.setTypesElements()
        __select.setType(type ? type : '', element)
        __select.select.element = item_element
    }, [])

    return (
        <>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <label className="formLabel">{i.t('def:select_link_type')}</label>
                    <Select
                        style={{ width: `100%` }}
                        options={types.length > 0 ? __select.types.filter((el: any) => types.findIndex((l: any) => el.value === l) === -1) : __select.types}
                        onChange={(e) => __select.setType(e)}
                        value={__select.select.type}
                    />
                </Col>
                <Col span={24}>
                    {__select.select.type === 'category' && <SelectCategories elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'product_group' && <SelectGroup elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'article' && <SelectNews elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'article_categories' && <SelectArticleCategories elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'product' && <SelectProduct elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'custom_product_list' && <CustomProductListsLayout type="group" multiple={false} selectedElements={[item_element]} />}
                    {__select.select.type === 'contacts' && <SelectContact elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'place' && <SelectPoints elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'places_group' && <SelectPointsGroup elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'feedback' && <SelectFeedback elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'forms' && <SelectForm elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'promo' && <SelectPromo elementID={__select.select.value} element={item_element} />}
                    {__select.select.type === 'calendar' && <SelectCalendar elementID={__select.select.value} element={item_element} />}

                    {(__select.select.type === 'url' || __select.select.type === 'url_blank') &&
                        <Input onChange={(e) => __select.select.value = e.target.value} value={__select.select.value} />
                    }

                </Col>
            </Row>
        </>
    )
})

export const SelectArticleCategories = observer(({ elementID, element = null }: any) => {

  useEffect(() => {
    __select.getArticleCategories()
  }, [])

  const columns = [
    {
      title: "ID",
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: i.t('news:name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: i.t('news:actions'),
      render: (record: any) =>
        <Space>
          <Button
            type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
            onClick={() => {
              __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)
              __select.setElement(`${elementID}` === `${record.id}` ? [] : record)
            }}
          >
            {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
          </Button>
          {record.subcategories
            &&
            <Button
              type="dashed"
              onClick={() => __select.getArticleCategories(record.id, true)}
            >
              {i.t('def:jump.jump')}
            </Button>
          }
        </Space>
    },
  ]

  return(
    <>
      {element &&
        <>
          <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.name}</label>
        </>
      }
      <label className="label_default">{i.t('def:select_news')}</label>
      <br/>
      <Button
        type={elementID === '' ? 'primary' : 'dashed'}
        onClick={() => __select.setValue('')}
      >{i.t('def:jump.news')}</Button>

      {__select.categoryID > 0 && <Button onClick={() => __select.getArticleCategories(null, true)}>{i.t('def:return_main_category')}</Button>}

      <br/><br/>
      <Table
        columns={columns}
        dataSource={__select.list}
        pagination={{
          position: ['bottomLeft'],
          hideOnSinglePage: true,
          current: Number(__select.page),
          pageSize: __select.limit,
          total: __select.total,
          showSizeChanger: false,
          onChange: (e) => {
            __select.setPage(e)
            __select.getArticleCategories()
          }
        }}
      />
    </>
  )

})

const SelectNews = observer(({ elementID, element = null }: any) => {

    useEffect(() => {
        __select.getArticles()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('news:view'),
            dataIndex: 'enabled',
            key: 'enabled'
        },
        {
            title: i.t('news:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Button
                    type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
                    onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
                >
                    {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
                </Button>
        },
    ]

    return(
        <>
            {element &&
                <>
                    <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.name}</label>
                </>
            }
            <label className="label_default">{i.t('def:select_news')}</label>
            <br/>
            <Button
                type={elementID === '' ? 'primary' : 'dashed'}
                onClick={() => __select.setValue('')}
            >{i.t('def:jump.news')}</Button>
            <br/><br/>
          <Row>
            <Col span={24}>
              <Input
                onChange={(e) => {
                  __select.setPage(1)
                  __select.filter.name = e.target.value
                  __select.getArticles()
                }}
                placeholder="Поиск по названию"
              />
            </Col>
          </Row>
          <br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__select.page),
                    pageSize: __select.limit,
                    total: __select.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __select.setPage(e)
                        __select.getArticles()
                    }
                }}
            />
        </>
    )
})

const SelectGroup = observer(({ elementID, element = null }: any) => {
    useEffect(() => {
      __select.page = 1
        __group.getList()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('def:actions.action'),
            render: (record: any) =>
                <Button
                    type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
                    onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
                >
                    {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
                </Button>
        },
    ]

    return(
        <>
            {element &&
                <>
                    <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.name}</label>
                </>
            }
            <Table
                columns={columns}
                dataSource={__group.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__group.pagination.page),
                    pageSize: __group.pagination.limit,
                    total: __group.pagination.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __group.setPage(e)
                        __group.getList()
                    }
                }}
            />
        </>
    )
})

export const SelectProduct = observer(({ elementID, element = null }: any) => {

    useEffect(() => {
      __select.page = 1
        __select.getProducts()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('categories:picture'),
            dataIndex: 'image',
            key: 'image',
            render: (picture: any) => {
                if(picture && picture.path)
                    return (
                        <Image
                            width={'100px'}
                            src={picture.path}
                        />
                    )
            },
        },
        {
            title: i.t('def:vendor'),
            dataIndex: 'id_crm',
            key: 'id_crm'
        },
        {
            title: i.t('news:name'),
            dataIndex: 'name',
            key: 'name',
            render: (el: any, record: any) => <>
              <p>{el}</p>
              <p>{record.params && record.params.map((param: any) => <Tag>{param.name}: {param.values[0].value}</Tag>)}</p>
            </>
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Button
                    type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
                    onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
                >
                    {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
                </Button>
        },
    ]

    return(
        <>
            {element &&
                <>
                    <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.name}</label>
                </>
            }
            <label className="label_default">{i.t('def:select_product')}</label>
            <br/>
            <Row>
                <Col span={24}>
                    <Input
                        onChange={(e) => {
                            __select.setPage(1)
                          __select.filter_name = e.target.value
                            __select.getProducts()
                        }}
                        placeholder="Поиск по названию товара"
                    />
                </Col>
            </Row>
            <br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__select.page),
                    pageSize: __select.limit,
                    total: __select.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __select.setPage(e)
                        __select.getProducts()
                    }
                }}
            />
        </>
    )
})

export const SelectProducts = observer(({ elementID, elements = [], visible = true, returnFunction = null, custom = false }: any) => {

    useEffect(() => {
      __select.page = 1
        __select.getProducts()

        // if(Array.isArray(elementID)) __select.select.value_array = elementID
        // else __select.select.value = elementID
    }, [])

    const columnsTop = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('categories:picture'),
            dataIndex: 'image',
            key: 'image',
            render: (image: any) => image ? <Image width={'100px'} src={image.path} /> : <></>,
        },
        {
            title: i.t('def:vendor'),
            dataIndex: 'id_crm',
            key: 'id_crm'
        },
        {
            title: i.t('news:name'),
            dataIndex: 'name',
            key: 'name',
            render: (el: any, record: any) => <>
              <p>{el}</p>
              <p>{record.params && record.params.map((param: any) => <Tag>{param.name}: {param.values[0].value}</Tag>)}</p>
            </>
        },
        {
            title: i.t('news:actions'),
            render: (record: any) => custom
              ? <>
                <Space>
                  <Button
                    icon={<UpOutlined />}
                    type="dashed"
                    onClick={() => __select.changePosition(record.id, 'up')}
                  />

                  <Button
                    icon={<DownOutlined />}
                    type="dashed"
                    onClick={() => __select.changePosition(record.id, 'down')}
                  />

                  <Button
                    type={elementID.findIndex((el: any) => `${el.id}` === `${record.id}`) > -1 ? 'primary' : 'dashed'}
                    onClick={() => __select.setValueMultiple(record.id, record, returnFunction, custom)}
                  >
                    {elementID.findIndex((el: any) => `${el.id}` === `${record.id}`) > -1 ? i.t('def:selected') : i.t('def:select')}
                  </Button>
                </Space>

              </>
              : <>
                <Button
                  type={elementID.findIndex((el: any) => `${el}` === `${record.id}`) > -1 ? 'primary' : 'dashed'}
                  onClick={() => __select.setValueMultiple(record.id, record, returnFunction)}
                >
                  {elementID.findIndex((el: any) => `${el}` === `${record.id}`) > -1 ? i.t('def:selected') : i.t('def:select')}
                </Button>
              </>

        },
    ]

  const columns = [
    {
      title: "ID",
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: i.t('categories:picture'),
      dataIndex: 'image',
      key: 'image',
      render: (image: any) => image ? <Image width={'100px'} src={image.path} /> : <></>,
    },
    {
      title: i.t('def:vendor'),
      dataIndex: 'id_crm',
      key: 'id_crm'
    },
    {
      title: i.t('news:name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: i.t('news:actions'),
      render: (record: any) => custom
        ? <>
          <Button
            type={elementID.findIndex((el: any) => `${el.id}` === `${record.id}`) > -1 ? 'primary' : 'dashed'}
            onClick={() => __select.setValueMultiple(record.id, record, returnFunction, custom)}
          >
            {elementID.findIndex((el: any) => `${el.id}` === `${record.id}`) > -1 ? i.t('def:selected') : i.t('def:select')}
          </Button>
        </>
        : <>
          <Button
            type={elementID.findIndex((el: any) => `${el}` === `${record.id}`) > -1 ? 'primary' : 'dashed'}
            onClick={() => __select.setValueMultiple(record.id, record, returnFunction)}
          >
            {elementID.findIndex((el: any) => `${el}` === `${record.id}`) > -1 ? i.t('def:selected') : i.t('def:select')}
          </Button>
        </>

    },
  ]

    return(
        <>
            <br/>
            <Space>
                <Button>{i.t('def:selected_products')}: {elementID.length}</Button>
                <Button danger onClick={() => __select.select.value_array = []}>{i.t('def:reset')}</Button>
            </Space>
            <br/><br/>
          {visible ?
            <>
              <label>{i.t('def:selectable_products')}</label>
              <Table
                columns={columnsTop}
                dataSource={elements}
                pagination={false}
                size="small"
              />
              <br/><br/>
            </>
            : <></>
          }

            <label>{i.t('def:select_products')}</label>
            <br/>
            <Row>
                <Col span={24}>
                    <Input
                        onChange={(e) => {
                            __select.setPage(1)
                          __select.filter_name = e.target.value
                            __select.getProducts()
                        }}
                        placeholder="Поиск по названию товара"
                    />
                </Col>
            </Row>
            <br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__select.page),
                    pageSize: __select.limit,
                    total: __select.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __select.setPage(e)
                        __select.getProducts()
                    }
                }}
            />
        </>
    )
})

const SelectContact = observer(({ elementID, element = null }: any) => {

    useEffect(() => {
      __select.page = 1
        __select.getContacts()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: i.t('def:type'),
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: i.t('def:value'),
            dataIndex: 'value',
            key: 'value'
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Button
                    type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
                    onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
                >
                    {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
                </Button>
        },
    ]

    return(
        <>
            {element &&
                <>
                    <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.title}</label>
                </>
            }
            <label className="label_default">{i.t('def:select_contact')}</label>
            <br/>
            <Button
                type={elementID === '' ? 'primary' : 'dashed'}
                onClick={() => __select.setValue('')}
            >{i.t('def:jump.contacts')}</Button>
            <br/><br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={false}
            />
        </>
    )
})

const SelectPoints = observer(({ elementID, element = null }: any) => {

    useEffect(() => {
      __select.page = 1
        __select.getPoints()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Button
                    type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
                    onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
                >
                    {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
                </Button>
        },
    ]

    return(
        <>
            {element &&
                <>
                    <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.name}</label>
                </>
            }
            <label className="label_default">{i.t('def:select_depart')}</label>
            <br/>
            <Button
                type={elementID === '' ? 'primary' : 'dashed'}
                onClick={() => __select.setValue('')}
            >{i.t('def:jump.depart')}</Button>
            <br/><br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__select.page),
                    pageSize: __select.limit,
                    total: __select.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __select.setPage(e)
                        __select.getPoints()
                    }
                }}
            />
        </>
    )
})

const SelectPointsGroup = observer(({ elementID, element = null }: any) => {

    useEffect(() => {
      __select.page = 1
        __select.getPointsGroups()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Button
                    type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
                    onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
                >
                    {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
                </Button>
        },
    ]

    return(
        <>
            {element &&
                <>
                    <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.name}</label>
                </>
            }
            <label className="label_default">{i.t('def:Select Places Group')}</label>
            <br/>
            <Button danger onClick={() => __select.setValue('')}>{i.t('def:reset')}</Button>
            <br/><br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__select.page),
                    pageSize: __select.limit,
                    total: __select.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __select.setPage(e)
                        __select.getPointsGroups()
                    }
                }}
            />
        </>
    )
})

const SelectFeedback = observer(({ elementID, element = null }: any) => {

    useEffect(() => {
      __select.page = 1
        __select.getFeedback()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Button
                    type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
                    onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
                >
                    {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
                </Button>
        },
    ]

    return(
        <>
            {element &&
                <>
                    <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.title}</label>
                </>
            }
            <label className="label_default">{i.t('def:select_feedback')}</label>
            <br/>
            <Button
                type={elementID === '' ? 'primary' : 'dashed'}
                onClick={() => __select.setValue('')}
            >{i.t('def:jump.feedback')}</Button>
            <br/><br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__select.page),
                    pageSize: __select.limit,
                    total: __select.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __select.setPage(e)
                        __select.getFeedback()
                    }
                }}
            />
        </>
    )
})

const SelectForm = observer(({ elementID, element = null }: any) => {

    useEffect(() => {
      __select.page = 1
        __select.getForm()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Button
                    type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
                    onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
                >
                    {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
                </Button>
        },
    ]

    return(
        <>
            {element &&
                <>
                    <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.title}</label>
                </>
            }
            <label className="label_default">{i.t('def:select_form')}</label>
            <br/>
            <Button
                type={elementID === '' ? 'primary' : 'dashed'}
                onClick={() => __select.setValue('')}
            >{i.t('def:jump.forms')}</Button>
            <br/><br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__select.page),
                    pageSize: __select.limit,
                    total: __select.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __select.setPage(e)
                        __select.getForm()
                    }
                }}
            />
        </>
    )
})

export const SelectCategories = observer(({ elementID, element = null, type = 'jump' }: any) => {

    useEffect(() => {
      __select.page = 1
        __select.getCategories()

    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'value',
            key: 'value'
        },
      {
        title: "",
        dataIndex: 'image',
        key: 'image',
        render: (e: string) => <Image
          width={'100px'}
          src={e}
        />
      },
        {
            title: i.t('def:name'),
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Space>
                    <Button
                        type={`${elementID}` === `${record.value}` ? 'primary' : 'dashed'}
                        onClick={() => {
                            __select.setValue(`${elementID}` === `${record.value}` ? '' : record.value)
                            __select.setElement(`${elementID}` === `${record.value}` ? [] : record)
                        }}
                    >
                        {`${elementID}` === `${record.value}` ? i.t('def:selected') : i.t('def:select')}
                    </Button>
                    {record.subcategory &&
                        <Button
                            type="dashed"
                            onClick={() => __select.getCategories(record.value, true)}
                        >
                            {i.t('def:jump.jump')}
                        </Button>
                    }
                </Space>
        },
    ]

    return(
        <>
            {element &&
                <>
                    <label className="label_default">{i.t('def:selected')}: {element.name}</label>
                </>
            }
            <label className="label_default">{i.t('def:select_category')}</label>
            <br/>
            <Space>
                <Button
                    type={elementID === '' ? 'primary' : 'dashed'}
                    onClick={() => {
                        __select.setValue('')
                        __select.setElement([])
                    }}
                >
                    {type === 'custom_list' ? (<>{i.t('def:reset')}</>) : (<>{i.t('def:jump.category')}</>)}
                </Button>
                {__select.categoryID > 0 && <Button onClick={() => __select.getCategories(0, true)}>{i.t('def:return_main_category')}</Button>}
            </Space>
            <br/><br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__select.page),
                    pageSize: __select.limit,
                    total: __select.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __select.setPage(e)
                        __select.getCategories(__select.categoryID)
                    }
                }}
            />
        </>
    )
})

export const SelectStatus = observer(({ elementID }: any) => {

    useEffect(() => {
      __select.page = 1
        __select.getStatuses()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Space>
                    <Button
                        type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
                        onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
                    >
                        {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
                    </Button>
                </Space>
        },
    ]

    return(
        <>
            <label className="label_default">{i.t('def:Select Status')}</label>
            <br/>
            <Space>
                <Button
                    type={elementID === '' ? 'primary' : 'dashed'}
                    onClick={() => __select.setValue('')}
                >
                    {i.t('def:jump.category')}
                </Button>
            </Space>
            <br/><br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__select.page),
                    pageSize: __select.limit,
                    total: __select.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __select.setPage(e)
                        __select.getStatuses()
                    }
                }}
            />
        </>
    )
})

export const SelectPromo = observer(({ elementID, element = null }: any) => {

    useEffect(() => {
      __select.page = 1
        __select.getPromo()
    }, [])

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('news:actions'),
            render: (record: any) =>
                <Space>
                    <Button
                        type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
                        onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
                    >
                        {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
                    </Button>
                </Space>
        },
    ]

    return(
        <>
            {element &&
                <>
                    <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.name}</label>
                </>
            }
            <label className="label_default">{i.t('def:select_promo')}</label>
            <br/>
            <Space>
                <Button
                    type={elementID === '' ? 'primary' : 'dashed'}
                    onClick={() => __select.setValue('')}
                >
                    {i.t('def:jump.promo')}
                </Button>
            </Space>
            <br/><br/>
            <Table
                columns={columns}
                dataSource={__select.list}
                pagination={{
                    position: ['bottomLeft'],
                    hideOnSinglePage: true,
                    current: Number(__select.page),
                    pageSize: __select.limit,
                    total: __select.total,
                    showSizeChanger: false,
                    onChange: (e) => {
                        __select.setPage(e)
                        __select.getPromo()
                    }
                }}
            />
        </>
    )
})

export const SelectCalendar = observer(({ elementID, element = null }: any) => {

  useEffect(() => {
    __select.page = 1
    __select.getCalendars()
  }, [])

  const columns = [
    {
      title: "ID",
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: i.t('def:name'),
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: i.t('news:actions'),
      render: (record: any) =>
        <Space>
          <Button
            type={`${elementID}` === `${record.id}` ? 'primary' : 'dashed'}
            onClick={() => __select.setValue(`${elementID}` === `${record.id}` ? '' : record.id)}
          >
            {`${elementID}` === `${record.id}` ? i.t('def:selected') : i.t('def:select')}
          </Button>
        </Space>
    },
  ]

  return(
    <>
      {element &&
        <>
          <label className="label_default">{i.t('def:selected')}: #{element.id} - {element.name}</label>
        </>
      }
      <label className="label_default">{i.t('def:Select calendar')}</label>
      <br/>
      <br/><br/>
      <Table
        columns={columns}
        dataSource={__select.list}
        pagination={{
          position: ['bottomLeft'],
          hideOnSinglePage: true,
          current: Number(__select.page),
          pageSize: __select.limit,
          total: __select.total,
          showSizeChanger: false,
          onChange: (e) => {
            __select.setPage(e)
            __select.getPromo()
          }
        }}
      />
    </>
  )
})