import { observer } from 'mobx-react-lite'
import { Row, Col, Select, Divider, InputNumber, Input, Space, Button, Modal, Image } from "antd";
import { SwitchBlock } from '../../../../components/SwitchBlock'
import i from '../../../../translations/i'
import { __product } from '../__product'
import { runInAction } from 'mobx'
import React, { useEffect } from 'react'
import __select from "../../../../helpers/select_entity/__select.controller";
import {
  SelectCategories,
  SelectEntity,
  SelectProducts,
  SelectStatus
} from "../../../../helpers/select_entity/SelectEntity";
import { CloseOutlined, EyeOutlined } from '@ant-design/icons'
import controller from "../../../shop/feedback/feedback.controller";

export const ProductSet = observer(() => {

  useEffect(() => {
    __product.setSets()
  }, [])

  const columns = [
    {
      title: "ID",
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: i.t('categories:picture'),
      key: 'image',
      render: (record: any) => {
        if(record.product.image && record.product.image.path)
          return (
            <Image
              width={'100px'}
              src={record.product.image.path}
            />
          )
      },
    },
    {
      title: i.t('def:vendor'),
      key: 'id_crm',
      render: (record: any) => record.product.id_crm
    },
    {
      title: i.t('news:name'),
      key: 'name',
      render: (record: any) => record.product.name
    },
    {
      title: i.t('news:actions'),
      render: (record: any) =>
        <Button onClick={() => __product.deleteSet(record.product_id)}>{i.t('def:actions.remove')}</Button>
    },
  ]

  return(
    <>
      <Row gutter={[20, 20]} align="bottom">
        <Col span={24}>
          <SwitchBlock array={[
            { label: i.t('def:is_set'), click: (e: any) => __product.item.is_set = e, value: __product.item.is_set },
            { label: i.t('def:is_set_contents'), click: (e: any) => __product.item.is_set_contents = e, value: __product.item.is_set_contents },
            { label: i.t('def:is_set_disassembled'), click: (e: any) => __product.item.is_set_disassembled = e, value: __product.item.is_set_disassembled },
          ]} />
          <Divider />
        </Col>
        <Col span={24}>
          <Select
            value={__product.item.is_set_view_type}
            style={{ width: 100 + '%' }}
            onChange={e => __product.item.is_set_view_type = e}
            options={[
              {label: i.t('def:type_view_isset_1'), value: 1},
              {label: i.t('def:type_view_isset_2'), value: 2},
              {label: i.t('def:type_view_isset_3'), value: 3},
              {label: i.t('def:type_view_isset_4'), value: 4}
            ]}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <Space size="large">
                <Button
                  type="primary"
                  onClick={() => __product.modalSetsOpened = true}
                >{i.t('def:Add products')}</Button>
              </Space>
            </Col>
            <Col span={24}>
              <label>{i.t('def:set_composition_heading')}</label>
              <Input
                placeholder={i.t('def:kit_composition')}
                value={__product.item.is_set_label}
                onChange={e => __product.item.is_set_label = e.target.value}
              />
            </Col>
            <Divider />
            <Col span={24}>
              <h3>{__product.item.is_set_label ? __product.item.is_set_label : i.t('def:kit_composition')}:</h3>
            </Col>
            <Col span={24}>
              <Row gutter={[5, 5]} justify="space-around" align="middle">
                {__product.item.sets.map((el: any) => (
                  <>
                    <Divider />
                    <Col span={24}>
                      <Row gutter={[5, 5]}>
                        <Col span={24}>
                          <Row justify="space-around" align="middle">
                            <Col span={4}>
                              {el.product.image && el.product.image.path ? <Image width={'80px'} src={el.product.image.path} /> : <></>}
                            </Col>
                            <Col span={16}>
                              <Row gutter={[5, 5]}>
                                <Col span={24}>
                                  <p>{el.product.name}</p>
                                </Col>
                                <Col span={24}>
                                  <InputNumber
                                    value={el.count}
                                    onChange={(e: any) => el.count = e ? e : 1}
                                    addonAfter={el.product.unit ? el.product.unit.name : ''}
                                  />
                                </Col>
                              </Row>
                            </Col>
                            <Col span={4}>
                              <Space>
                                <Button
                                  href={`/shop/catalog/product/${el.product_id}`}
                                  target="_blank"
                                  icon={<EyeOutlined />}
                                  type="dashed"
                                  htmlType="button"
                                />
                                <Button
                                  onClick={() => __product.deleteSet(el.product_id)}
                                  icon={<CloseOutlined />}
                                  type="dashed"
                                  danger
                                />
                              </Space>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>

      </Row>


      <Modal
        open={__product.modalSetsOpened}
        footer={null}
        onCancel={() => __product.modalSetsOpened = false}
        width={1000}
        centered={true}
        maskClosable={false}
        className="cpl__modal"
      >
        <Button
          type="primary"
          onClick={() => __product.saveSets()}
          className="cpl__modal-close"
        >{i.t('def:actions.save')}</Button>

        <SelectProducts
          elementID={__select.select.value_array}
          returnFunction={(e: any) => __product.item.sets.push({
            product_id: e.id,
            count: 1,
            product: e
          })}
          visible={false}
        />


      </Modal>
    </>
  )
})