import { observer } from 'mobx-react-lite'
import __apps, { AppInterface } from './__apps'
import React, { useEffect } from 'react'
import {
  Row,
  Col,
  Button,
  Space,
  Input,
  Modal,
  Select,
  Switch,
  Typography,
  Tag,
  Popconfirm,
  Divider,
  InputNumber
} from "antd";
import { StopOutlined } from '@ant-design/icons'
import { Titles } from '../../layouts/Titles'
import { runInAction } from 'mobx'
import i from '../../translations/i'
import { storeLocal } from '../../helpers/stores'
import { SwitchBlock } from "../../components/SwitchBlock";

export const Apps = observer(() => {

    useEffect(() => {
        return () => __apps.getList()
    }, [])

    return(
        <>
            <Titles title={i.t('def:apps.title')} breadcrumb={[ { title: i.t('def:apps.title') } ]} />
            <Row gutter={[20, 20]}>
              {__apps.installed.length > 0
                ? <>
                  <Col span={24}>
                    <h2>Установленные:</h2>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[20, 20]}>
                      {__apps.installed.map((el: AppInterface, index: number) => <AppsElement el={el} key={`app_${index}`} />)}
                    </Row>
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <h2>Остальные приложения:</h2>
                  </Col>
                </>
                : <></>
              }
                <Col span={24}>
                    <Row gutter={[20, 20]}>
                        {__apps.list.length
                            ? __apps.list.map((el: AppInterface, index: number) => <AppsElement el={el} key={`app_${index}`} />)
                            : <></>
                        }
                    </Row>
                </Col>
            </Row>

            <AppsDetail />
        </>
    )
})

const AppsElement = ({ el }: { el: AppInterface }) => {

    return(
        <Col xs={24} md={12} lg={8}>
            <Row>
                <Col span={24} className="app">
                    {el.icon
                        ? <><img src={el.icon} alt='' style={{ width: '50px' }} /><br/><br/></>
                        : <></>}

                    <p className="name">{el.config.local.name}</p>
                    <p className="description">{el.config.local.annotation}</p>

                    <div className="tags">
                        {el.category === ''
                            ? <Tag color="processing">{i.t('def:apps.development')}</Tag>
                            : <>
                                {el.installed ? <Tag color="success">{i.t('def:apps.installed')}</Tag> : <></>}
                                {el.installed ?
                                    el.enabled ? <Tag color="success">{i.t('def:states.included')}</Tag> : <Tag color="warning">{i.t('def:states.turned')}</Tag>
                                    : <></>}
                            </>
                        }
                    </div>

                  <div className="apps_list-button">
                    <Space>
                      {el.category === ''
                        ?   <Button disabled>{i.t('def:apps.later')}</Button>
                        : <>
                          {Number(storeLocal.get('pricing_plan') < el.min_rate)
                            ? <Button disabled>{i.t('def:apps.forbidden')}</Button>
                            : <>
                              <Button type={el.installed ? "dashed" : "primary"} onClick={() => __apps.openModal(el.id)}>{el.installed ? i.t('def:settings') : i.t('def:actions.connect')}</Button>
                              {(el.installed) ? <Popconfirm
                                title={i.t('def:confirms.delete')}
                                onConfirm={() => __apps.delete(el.id)}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button type="dashed" icon={<StopOutlined />} danger />
                              </Popconfirm> : <></>}
                            </>
                          }
                        </>
                      }

                    </Space>
                  </div>
                </Col>
            </Row>
        </Col>
    )
}

export const AppsDetail = observer(() => {

    return(
        <Modal open={__apps.modal} footer={null} onCancel={() => __apps.closeModal()} maskClosable={false} width={1200}>
            <form onSubmit={(e) => __apps.save(e)}>
                {__apps.data &&
                    <Row gutter={[20, 20]}>
                      <Col span={8}>
                        <Row gutter={[20, 20]}>
                          <Col span={24}>
                            {__apps.data.icon
                              ? <><img src={__apps.data.icon} alt='' style={{ width: '100px' }} /><br/><br/></>
                              : <></>}
                          </Col>
                          <Col span={24}>
                            <Typography.Title level={2}>{__apps.data.config.local.name ?? 'name'}</Typography.Title>
                          </Col>
                          <Col span={24}>
                            <Typography.Text>{__apps.data.config.local.description ?? ''}</Typography.Text>
                          </Col>
                          <Col span={24}>
                            <SwitchBlock array={[
                              { label: i.t(`def:active`), click: (e: any) => runInAction(() => __apps.data.enabled = e), value: __apps.data.enabled }
                            ]}
                            />
                          </Col>
                          <Col span={24}>
                            <Button htmlType="submit" type="primary">{i.t('def:actions.save')}</Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={16}>
                        <Row gutter={[20, 20]}>
                          {__apps.data.installed ? <Col span={24}><Tag color="success">{i.t('def:apps.installed')}</Tag></Col> : <></>}

                          <Col span={24}>
                            <Row>
                              <Col span={24}>
                                <p>{i.t(`def:appEvents.events`)}</p>
                              </Col>
                              <Col span={24}>
                                <SwitchBlock array={__apps.data.config.events.split(',').map((event: any) => ({
                                  label: i.t(`def:appEvents.${event}`),
                                  click: (e: any) => __apps.setEvent(event),
                                  value: !(__apps.data.data.eventsDisabled && __apps.data.data.eventsDisabled.find((e: string) => e === event))
                                }))}
                                />
                              </Col>
                            </Row>
                          </Col>

                          {Object.keys(__apps.data.data).map((key: string, index: number) => {

                            const data = __apps.data.data[key]

                            if(!__apps.data.installed && data.then) return ''

                            return (
                              <Col span={24} key={`setting_application_${index}`}>

                                {data.type === 'string' || data.type === 'select_array' || data.type === 'select'
                                  ? <label>{__apps.data.config.local.settings[data.key] ?? data.key}</label>
                                  : <></>
                                }

                                {data.type === 'string'
                                  ? <Input
                                    value={data.value}
                                    onChange={(e) => runInAction(() => data.value = e.target.value)}
                                    required={data.required === "true"}
                                  /> : <></>}

                                {data.type === 'switch'
                                  ? <SwitchBlock array={[
                                    { label: __apps.data.config.local.settings[data.key] ?? data.key, click: (e: any) => runInAction(() => data.value = e), value: data.value }
                                  ]}
                                  /> : <></>}

                                {data.type === 'select_array'
                                  ? <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    value={data.value ?? []}
                                    options={data.options}
                                    onChange={(e) => {
                                      runInAction(() => data.value = e)
                                      console.log(data)
                                      if(data.save) __apps.saveApplication()
                                    }}
                                  />
                                  : <></>}

                                {data.type === 'select'
                                  ? <Select
                                    style={{ width: '100%' }}
                                    value={data.value ?? null}
                                    options={data.options}
                                    onChange={(e) => runInAction(() => data.value = e)}
                                  />
                                  : <></>}
                              </Col>
                            )
                          })}
                        </Row>

                        <Divider />

                        <Row gutter={[20, 20]}>
                          <Col span={12}>
                            <p>Пользовательские поля и связи</p>
                          </Col>
                          <Col span={12}>
                            <Button size="small" type="dashed" onClick={() => __apps.addCustomField()}>{i.t('def:actions.add')}</Button>
                          </Col>
                          <Col span={24}>
                            <Row gutter={[20, 20]}>
                              {__apps.data.fields && __apps.data.fields.map((field: any, index: number) => (
                                <Col span={24}>
                                  <Row gutter={[15, 15]}>
                                    <Col span={6}>
                                      <label>Комментарий</label>
                                      <Input
                                        value={field.comment ?? ''}
                                        onChange={(e) => field.comment = e.target.value}
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <label>Ключ поля</label>
                                      <Input
                                        value={field.field_key ?? ''}
                                        onChange={(e) => field.field_key = e.target.value}
                                        required
                                      />
                                    </Col>
                                    <Col span={6}>
                                      <label>Сущность</label>
                                      <Input
                                        value={field.entity_type ?? ''}
                                        onChange={(e) => field.entity_type = e.target.value}
                                      />
                                    </Col>
                                    <Col span={4}>
                                      <label>ID сущности</label>
                                      <InputNumber
                                        style={{ width: '100%' }}
                                        value={field.entity_id ?? ''}
                                        onChange={(e) => field.entity_id = e}
                                      />
                                    </Col>
                                    {(field.data && typeof field.data.value === 'string')
                                      ? (
                                        <>
                                          <Col span={8}>
                                            <label>Пользовательское значение</label>
                                            <Input
                                              value={field.data.value}
                                              onChange={(e) => field.data.value = e.target.value}
                                            />
                                          </Col>
                                          <Col span={24}>
                                            <Button onClick={() => __apps.deleteField(index)} size="small" danger>{i.t('def:actions.remove')}</Button>
                                          </Col>
                                          <Divider />
                                        </>
                                      )
                                      : <>
                                        <Col span={24}>
                                          <Button onClick={() => __apps.deleteField(index)} size="small" danger>{i.t('def:actions.remove')}</Button>
                                        </Col>
                                      </>}
                                  </Row>
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                }
            </form>
        </Modal>
    )
})