import { makeAutoObservable, runInAction } from 'mobx'
import { openApi } from '../../../../helpers/request'
import __multiple from '../../../../helpers/multiple_actions/__multiple'

class answersController {

    constructor() {
        makeAutoObservable(this)
    }

    list = []

    pagination = {
        total: 0,
        limit: 10,
        page: 1,
        offset: 0
    }

    filters = []
    filtersValues: any = {}

    feedback_id: number = 0

    answer = {
        device_info: '',
        source: '',
        elements: []
    }

    export(feedback_id: number, type: string = 'xlsx') {

        let path = `/feedback/export/${feedback_id}/${type}?page=1`

        if(Object.keys(this.filtersValues).length > 0){
            Object.keys(this.filtersValues).map((key: any) => {
                if(this.filtersValues[key] !== null) path += `&filter[${key}]=${this.filtersValues[key]}`
            })
        }

        openApi('get', path)
            .then(result => {
                console.log(result)
                window.open(result.data)
            })
            .catch(error => {
                console.log(error.response)
            })
    }

    get(formID: number | string, elementID: number | string = 0, sorter: any = false){
        let path = ''
        if(elementID > 0) path = `/feedback/answer/${elementID}`
        else {
            path = `/feedback/answers/${formID}?limit=${this.pagination.limit}&page=${this.pagination.page}`
            if(sorter !== false){
                let direction = sorter.order
                if(direction !== undefined){
                    direction = (direction === 'ascend') ? 'asc' : 'desc'
                    path += `&sorting_type=${sorter.columnKey}&sorting_action=${direction}`
                }
            }
        }
        if(Object.keys(this.filtersValues).length > 0){
            Object.keys(this.filtersValues).map((key: any) => {
                if(this.filtersValues[key] !== null) path += `&filter[${key}]=${this.filtersValues[key]}`
            })
        }

        openApi('get', path)
            .then(result => {
                console.log(result)
                if(elementID > 0) this.answer = result.data
                else {
                    this.pagination.total = result.total
                    this.pagination.page = result.page
                    this.pagination.limit = result.limit
                    this.list = result.data
                }
            })
    }

    setPage(formID: number | string, page: number) {
        this.pagination.page = page
        this.get(formID)
    }

    getFilters(formID: number | string) {
        openApi('get', `/feedback/filters/${formID}`)
            .then(result => {
                console.log(result)
                runInAction(() => this.filters = result.data)
            })
    }

    setFilter(name: string, value: any, formID: number | string) {
        this.filtersValues[name] = value
        this.get(formID)
    }

    deleteAnswer(answer_id: number, form_id: number, redirect_to_list: boolean = false) {
        openApi('delete', `/feedback/answer/${answer_id}`)
            .then(result => {
                if(redirect_to_list) window.location.href = `/shop/feedback/${form_id}/answers`
                else this.get(form_id)
            })
    }

    readAll() {
        openApi('post', `/feedback/read/answers/${this.feedback_id}`)
            .then(result => {
                this.get(this.feedback_id)
                __multiple.clear()
            })
    }

    multipleDelete() {
        const data = new FormData()

        __multiple.selected.map((el: number) => data.append('elements[]', `${el}`))

        openApi('post', `/feedback/delete/multiple/${this.feedback_id}`, data)
            .then(result => {
                this.get(this.feedback_id)
                __multiple.clear()
            })
    }

    multipleRead() {
        const data = new FormData()

        __multiple.selected.map((el: number) => data.append('elements[]', `${el}`))

        openApi('post', `/feedback/read/answers/${this.feedback_id}`, data)
            .then(result => {
                this.get(this.feedback_id)
                __multiple.clear()
            })
    }

}

export default new answersController()