import { observer } from "mobx-react-lite"
import controller from './coupon.controller'
import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import i from "../../../translations/i";
import { Titles } from "../../../layouts/Titles";
import {
  Select,
  Col,
  Divider,
  Input,
  Row,
  ColorPicker,
  Statistic,
  Button,
  InputNumber,
  DatePicker,
  Drawer,
  Table,
  Segmented, Space, Tooltip
} from "antd";
import { runInAction } from "mobx";
import { DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import { SwitchBlock } from "../../../components/SwitchBlock";
import dayjs from "dayjs";
import { SelectProduct } from "../../../helpers/select_entity/SelectEntity";
import __select from "../../../helpers/select_entity/__select.controller";
import { newDateTimeHelper } from "../../../modules/template/helpers/templateHelpers";
import __users from "../../users/users.controller";
import { CouponExUsers } from "./CouponExUsers";
import { CustomProductListsLayout } from "../../catalog/custom_product_lists/CustomProductListsLayout";
import { storeLocal } from "../../../helpers/stores";
import { __cpl } from "../../catalog/custom_product_lists/__cpl";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("UTC")

export const Coupon = observer(() => {

  const { id }: any = useParams()

  useEffect(() => {
    controller.getCoupon(id)
    controller.getStatistics(id)
  }, [])

  const buttons = [
    { title: i.t('def:actions.backList'), href: `/shop/coupons`, type: 'dashed' },
    { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
  ]

  const columns = [
    {
      title: i.t('promo:use'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: i.t('promo:datetime'),
      dataIndex: 'date',
      key: 'date',
      render: (item: any) => newDateTimeHelper(item)
    },
    {
      title: i.t('def:Order_without_number'),
      dataIndex: 'orderid',
      key: 'orderid',
      render: (item: any) => <>{item > 0 && <a href={`/shop/orders/${item}`}>#{item}</a>}</>
    },
    {
      title: "",
      dataIndex: 'userid',
      key: 'userid',
      render: (item: any) => <>{item > 0 && <a href={`/users/${item}`}>
        {i.t('promo:user')}
      </a>}</>
    }
  ]



  return(
    <>
      <form onSubmit={(e) => controller.save(e)}>
        <Titles
          title={i.t('def:Coupon edit')}
          buttons={buttons}
        />

        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Segmented<string>
              options={[
                { label: i.t("def:main"), value: 'main' },
                { label: i.t("def:Coupon conditions"), value: 'conditions' },
                { label: i.t("def:Coupon users"), value: 'users', disabled: storeLocal.get('pricing_plan') < 3 || storeLocal.get('black_mark') },
                { label: i.t("def:Coupon products"), value: 'products', disabled: storeLocal.get('pricing_plan') < 3 || storeLocal.get('black_mark') },
                { label: i.t("def:Statistics"), value: 'statistics' }
              ]}
              value={controller.segment}
              onChange={(value) => runInAction(() => controller.segment = value)}
            />
          </Col>
          <Col span={24}>
            {controller.segment === 'main' &&
              <>
                <Row gutter={[20, 20]}>
                  <Divider />
                  <Col span={24}>
                    <SwitchBlock array={[
                      { label: i.t('def:active'), click: (e: any) => controller.item.enabled = e, value: controller.item.enabled },
                      { label: i.t('def:Coupon public'), click: (e: any) => controller.item.public = e, value: controller.item.public },
                      { label: i.t('def:Coupon prohibit_write_off'), click: (e: any) => controller.item.prohibit_write_off = e, value: controller.item.prohibit_write_off, block: 3 },
                      { label: i.t('def:Coupon disable_accrual'), click: (e: any) => controller.item.disable_accrual = e, value: controller.item.disable_accrual, block: 3 },
                    ]} />
                  </Col>
                  <Divider />
                  <Col span={12}>
                    <label>{i.t('def:Coupon code')}</label>
                    <Input
                      value={controller.item.code}
                      onChange={(e: any) => {
                        const inputValue = e.target.value.replace(/[-—]/g, '');
                        runInAction(() => controller.item.code = inputValue);
                      }}
                      required
                    />
                  </Col>
                  <Col span={12}>
                    <label>{i.t('def:Coupon name')}</label>
                    <Input
                      value={controller.item.name}
                      onChange={(e: any) => runInAction(() => controller.item.name = e.target.value)}
                      required
                    />
                  </Col>
                  <Col span={12}>
                    <label>{i.t('def:id_crm')}</label>
                    <Input
                      value={controller.item.id_crm}
                      onChange={(e: any) => runInAction(() => controller.item.id_crm = e.target.value)}
                    />
                  </Col>
                  <Col span={24}>
                    <label>{i.t('def:Coupon text')}</label>
                    <Input.TextArea
                      value={controller.item.text}
                      onChange={(e: any) => runInAction(() => controller.item.text = e.target.value)}
                      required
                    />
                  </Col>
                  <Col span={24}>
                    <label>{i.t('def:Coupon description')}</label>
                    <Input.TextArea
                      value={controller.item.description}
                      onChange={(e: any) => runInAction(() => controller.item.description = e.target.value)}
                    />
                  </Col>
                  <Col span={12}>
                    <label className="formLabel">{i.t('def:Select color')}</label>
                    <ColorPicker
                      value={`${controller.item.color_hex}`}
                      onChange={(e: any) => runInAction(() => controller.item.color_hex = e.toHexString())}
                    />
                  </Col>
                </Row>
              </>
            }

            {controller.segment === 'conditions' &&
              <>
                <Row gutter={[20, 20]}>
                  <Col span={12}>
                    <label className="all-label">{i.t('def:Coupon date from')}</label>
                    <DatePicker
                      showTime
                      style={{ width: 100 + "%" }}
                      format="YYYY-MM-DD HH:mm"
                      minuteStep={10}
                      value={controller.item.datestart ? dayjs(controller.item.datestart * 1000) : null}
                      onChange={(time) => runInAction(() => controller.item.datestart = time.unix())}
                    />
                  </Col>
                  <Col span={12}>
                    <label className="all-label">{i.t('def:Coupon date to')}</label>
                    <DatePicker
                      showTime
                      style={{ width: 100 + "%" }}
                      format="YYYY-MM-DD HH:mm"
                      minuteStep={10}
                      value={controller.item.dateend ? dayjs(controller.item.dateend * 1000) : null}
                      onChange={(time) => runInAction(() => controller.item.dateend = time.unix())}
                    />
                  </Col>
                  <Divider />
                  <Col span={12}>
                    <label>{i.t('def:Coupon sum cart')}</label>
                    <InputNumber
                      value={controller.item.summcard}
                      onChange={(e: any) => runInAction(() => controller.item.summcard = e)}
                      style={{ width: `100%` }}
                    />
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <SwitchBlock array={[
                      { label: i.t('def:Coupon counts'), click: (e: any) => controller.item.nocount = e, value: controller.item.nocount },
                      { label: i.t('def:Coupon birthday'), click: (e: any) => controller.item.birthday = e, value: controller.item.birthday, block: 2 },
                      { label: i.t('def:Coupon one to user'), click: (e: any) => controller.item.onetouser = e, value: controller.item.onetouser },
                      { label: i.t('def:Coupon first order'), click: (e: any) => controller.item.firstorder = e, value: controller.item.firstorder },
                    ]} />
                  </Col>
                  {controller.item.birthday ?
                    <Col span={24}>
                      <label>{i.t('def:Coupon birthday days')}</label>
                      <InputNumber
                        value={controller.item.birthday_limit}
                        onChange={(e: any) => runInAction(() => controller.item.birthday_limit = e)}
                      />
                    </Col> : <></>
                  }
                  {!controller.item.nocount
                    ? <>
                      <Col span={12}>
                        <label>{i.t('def:Count all coupon')}</label>
                        <InputNumber
                          value={controller.item.countall}
                          onChange={(e: any) => runInAction(() => controller.item.countall = e)}
                          style={{ width: `100%` }}
                        />
                      </Col>
                      <Col span={12}>
                        <label>{i.t('def:Count coupon')}</label>
                        <InputNumber
                          value={controller.item.count}
                          onChange={(e: any) => runInAction(() => controller.item.count = e)}
                          style={{ width: `100%` }}
                        />
                      </Col>
                    </>
                    : <>
                    </>
                  }

                  <Divider />
                  <Col span={12}>
                    <label>{i.t('def:Select type coupon')}</label>
                    <Select
                      options={[
                        { label: i.t('def:Fix discount'), value: 0 },
                        { label: i.t('def:Percent discount'), value: 1 },
                        { label: i.t('def:Gift to order'), value: 2, disabled: storeLocal.get('pricing_plan') < 1 },
                      ]}
                      style={{ width: `100%` }}
                      value={controller.item.type}
                      onChange={(e: any) => runInAction(() => controller.item.type = e)}
                    />
                  </Col>
                  <Col span={12}>
                    <label>{i.t('def:Coupon value')}</label>
                    <InputNumber
                      suffix={controller.item.type === 1 ? '%' : null}
                      value={controller.item.value}
                      onChange={(e: any) => runInAction(() => controller.item.value = e)}
                      style={{ width: `100%` }}
                      disabled={controller.item.type === 2}
                    />
                  </Col>
                  <Divider />
                  {controller.item.type === 2 &&
                    <Col span={24}>
                      <SelectProduct
                        elementID={__select.select.value}
                        element={controller.item.product ?? null}
                      />
                    </Col>
                  }
                </Row>
              </>
            }

            {controller.segment === 'users' &&
              <>
                <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <CouponExUsers users={controller.item.users} />
                  </Col>
                </Row>
              </>
            }

            {controller.segment === 'products' &&
              <>
                <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <label className="all-label">{i.t('def:Coupon select products')}</label>

                    <SelectedLists />
                    <br/><br/>

                    <CustomProductListsLayout
                      setFunction={(e: any) => {
                        if(__cpl.selectedLists.findIndex((el: any) => el === e) > -1)
                          controller.item.custom_list = controller.item.custom_list.filter((el: any) => el.id !== e)
                      }}
                    />
                  </Col>
                </Row>
              </>
            }

            {controller.segment === 'statistics' &&
              <>
                <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <Statistic
                      title={i.t('def:Number of uses')}
                      value={controller.item.countHistory ?? 0}
                      precision={0}
                    />
                  </Col>
                  <Col span={24}>
                    <Row gutter={[20, 20]}>
                      <Col span={24}>
                        <DatePicker.RangePicker
                          format="DD.MM.YYYY"
                          onChange={(e: any, ee: any) => {
                            console.log(ee[0], ee[1])
                            controller.statistics.filter.date_from = ee[0]
                            controller.statistics.filter.date_to = ee[1]
                            controller.getStatistics(id)
                          }}
                          style={{ width: `100%` }}
                          // value={controller.filter.from ? [dayjs(controller.filter.from*1000).tz("UTC"), dayjs(controller.filter.to*1000).tz("UTC")] : null}
                        />
                      </Col>
                    </Row>
                    <Table
                      columns={columns}
                      dataSource={controller.statistics.data}
                      pagination={{
                        total: controller.statistics.total,
                        current: controller.statistics.page,
                        onChange: (e: any) => controller.onChangePageStatistics(e, id)
                      }}
                    />
                  </Col>
                </Row>
              </>
            }
          </Col>
        </Row>
      </form>

    </>
  )
})

const SelectedLists = observer(() => {

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: i.t('def:name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      key: 'actions',
      render: (record: any) => (
        <>
          <Button
            type={__cpl.selectedLists.findIndex((el: any) => el === record.id) > -1 ? 'primary' : 'dashed'}
            onClick={() => {
              if(__cpl.selectedLists.findIndex((el: any) => el === record.id) > -1) {
                controller.item.custom_list = controller.item.custom_list.filter((el: any) => el.id !== record.id);
              }

              __cpl.selectList(record.id, true)
            }}
          >
            {__cpl.selectedLists.findIndex((el: any) => el === record.id) > -1 ? i.t('def:selected') : i.t('def:select')}
          </Button>
        </>
      )
    }
  ]

  return(
    <>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <label className="all-label">{i.t('def:Selected lists')}</label>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={controller.item.custom_list}
            rowKey="id"
            size="small"
            pagination={false}
          />
        </Col>
      </Row>
    </>
  )
})