import { makeAutoObservable } from "mobx";
import { openApi } from "../../../helpers/request";
import { FormEvent } from "react";
import { notification } from "antd";
import i from "../../../translations/i";

class ApiConstructorController {

  types: any[] = []
  list: any[] = []
  item: any = {
    id: 0,
    type: 'check_isset_user',
    method: 'get',
    path: null,
    params: []
  }

  templates: any[] = []

  constructor() {
    makeAutoObservable(this)
  }

  getTypes() {
    openApi('get', '/rest_constructor/types')
      .then(result => {
        console.log(result)
        this.types = result.data.requests
        this.templates = result.data.params
      })
  }

  getList() {
    openApi('get', '/rest_constructor')
      .then(result => {
        console.log(result)
        this.list = result.data
      })
  }

  getItem(id: any) {
    if(id && id > 0) {
      openApi('get', `/rest_constructor/${id}`)
        .then(result => {
          console.log(result)
          this.item = result.data
        })
    }
  }

  save(e: FormEvent) {
    e.preventDefault()

    const data = new FormData()
    data.append('type', this.item.type)
    data.append('method', this.item.method)
    data.append('path', this.item.path)

    this.item.params.map((param: any, index: number) => {
      console.log(param)
      if(param.id && param.id > 0) data.append(`params[${index}][id]`, param.id)
      data.append(`params[${index}][location]`, param.location)
      data.append(`params[${index}][label]`, param.label)
      data.append(`params[${index}][value]`, param.value)
    })

    let uri = `/rest_constructor`

    if(this.item.id && this.item.id > 0) uri += `/${this.item.id}`

    openApi('post', uri, data)
      .then(result => {
        console.log(result)
        if(this.item.id && this.item.id > 0) {
          this.getItem(this.item.id)
          notification.success({
            message: i.t('def:success.complete'),
            description: i.t('def:success.edit')
          });
        } else {
          window.location.href = `/settings/rest`
        }
      })
      .catch(error => {
        notification.error({
          message: i.t('def:errors.error'),
          description: error.response.data.data.message ?? i.t('def:errors.error')
        });
      })
  }

  addParam() {
    this.item.params.push({
      id: null,
      label: '',
      value: '',
      location: 'data'
    })
  }

  deleteParam(index: number) {
    this.item.params.splice(index, 1)
  }

  deleteMethod(id: number) {
    if(window.confirm(i.t('def:confirms.delete')))
      openApi('delete', `/rest_constructor/${id}`)
        .then(result => {
          this.getList()
        })
  }

}

export default new ApiConstructorController()