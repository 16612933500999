import { observer, Observer } from 'mobx-react-lite'
import { __cpl } from './__cpl'
import { Button, Col, Popconfirm, Segmented, Space, Table, Tooltip, Row } from "antd";
import i from '../../../translations/i'
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons'
import __multiple from "../../../helpers/multiple_actions/__multiple";
import React from "react";

export const CustomProductListsList = observer(({type = 'list', selectedElements = [], multiple = true, setFunction = null}: any) => {
    let newArray: any = []
    selectedElements.map((el: any, index: number) => {
        if(el && el?.length !== 0) newArray.push({...el, key: index})
    })
    selectedElements = newArray

    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: i.t('def:name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: i.t('def:actions.actions'),
            key: 'action',
            render: (list: any) => (
                <Observer>
                    {() => (
                        <Space>
                            {type === 'list' &&
                                <>
                                    <Tooltip title={i.t('def:actions.edit')}>
                                        <Button
                                            type="dashed"
                                            shape="circle"
                                            href={`/catalog/lists/${list.id}`}
                                            icon={<SettingOutlined />}
                                        />
                                    </Tooltip>
                                </>
                            }
                            {type === 'group' &&
                                <>
                                    <Button
                                        type={__cpl.selectedLists.findIndex((el: any) => el === list.id) > -1 ? 'primary' : 'dashed'}
                                        onClick={() => {
                                            if(setFunction) setFunction(list.id)
                                            __cpl.selectList(list.id, multiple);
                                        }}
                                    >
                                        {__cpl.selectedLists.findIndex((el: any) => el === list.id) > -1 ? i.t('def:selected') : i.t('def:select')}
                                    </Button>
                                    <Tooltip title={i.t('def:actions.edit')}>
                                        <Button
                                            type="dashed"
                                            shape="circle"
                                            onClick={() => __cpl.setEditedList(list.id)}
                                            icon={<SettingOutlined />}
                                        />
                                    </Tooltip>
                                </>
                            }
                            <Tooltip title={i.t('def:actions.remove')} zIndex={5}>
                                <Popconfirm
                                    title={i.t('def:confirms.delete')}
                                    onConfirm={() => __cpl.deleteOne(list.id)}
                                    okText={i.t('def:yes')}
                                    cancelText={i.t('def:no')}
                                >
                                    <Button
                                        type="dashed"
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        danger
                                    />
                                </Popconfirm>
                            </Tooltip>
                        </Space>
                    )}
                </Observer>
            ),
        },
    ]

    return <>
        {type === 'group' && <>
            <Space>
                <Button>{i.t('def:selected')}: {__cpl.selectedLists.length}</Button>
                <Button danger onClick={() => __cpl.selectedLists = []}>{i.t('def:reset')}</Button>
            </Space>
            <br /><br />
            {selectedElements.length > 0 && <>
                <label>{i.t('def:Selected Lists')}</label>
                <Table
                    columns={columns}
                    dataSource={selectedElements}
                    pagination={false}
                    size="small"
                />
                <br /><br />
            </>}
        </>}
        <Row gutter={[20, 20]}>
            <Col span={24}>
                <Segmented
                  value={__cpl.is_system}
                  options={[
                      { label: i.t('def:is_system_false'), value: false },
                      { label: i.t('def:is_system_true'), value: true },
                  ]}
                  onChange={(e) => {
                      __cpl.is_system = e
                      __cpl.pagination = {
                          limit: 10,
                          page: 1,
                          total: 0
                      }
                      __cpl.getList()
                  }}
                />
            </Col>
            <Col span={24}>
                <Table
                  rowKey="id"
                  columns={columns}
                  dataSource={__cpl.list}
                  pagination={{
                      position: ['bottomLeft'],
                      hideOnSinglePage: true,
                      current: __cpl.pagination.page,
                      pageSize: __cpl.pagination.limit,
                      total: __cpl.pagination.total,
                      showSizeChanger: false,
                      onChange: (e: number) => __cpl.setPage(e)
                  }}
                />
            </Col>
        </Row>
    </>
})