import {observer} from "mobx-react-lite";
import i from '../../../../../translations/i'
import { Breadcrumb, Button, Col, Row, Select, Upload } from "antd";
import spsc from "../../controllers/shopProductStatuses.controller"
import React, { useEffect } from "react";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import { Helmet } from 'react-helmet'
import { SketchPicker } from 'react-color'
import { runInAction } from 'mobx'
import { deleteFile } from "../../../../../helpers/files.controller";

const Status = observer(({ status }) => {
    return(
        <>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 8 }}
                xxl={{ span: 8 }}
            >
                <form onSubmit={e => spsc.onSubmit(e)}>
                    <Row className="order-status" gutter={[20, 20]}>
                        {status.id !== spsc.status.id &&
                        <div className="for"><Button onClick={() => spsc.selectStatus(status.id)} shape="circle" type="dashed" icon={<SettingOutlined />} /></div>
                        }
                        <Col span={24}>
                            <p className="order_id">#{ status.id }</p>
                        </Col>
                        {status.id === spsc.status.id
                            ? <>
                                <span className="description">
                                    {i.t('product:statusName')}
                                </span>
                                <input
                                    type="text"
                                    className="status_input"
                                    placeholder={i.t('product:statusName')}
                                    value={spsc.status.name}
                                    onChange={e => spsc.onChangeStatus("name", e.target.value)}
                                    required
                                />

                                <Row gutter={[10, 10]}>
                                    <Col span={24}>
                                        <span className="description">
                                            {i.t('product:selectColorStatus')}
                                        </span>
                                    </Col>
                                    <Col>
                                        <SketchPicker
                                            color={`#${spsc.status.colorhex}`}
                                            onChange={(e) => runInAction(() => spsc.status.colorhex = e.hex.replace('#', ''))}
                                        />
                                    </Col>
                                  <Col>
                                    <span className="description">Внешний ID</span>
                                    <input
                                      type="text"
                                      className="status_input"
                                      placeholder="Внешний ID"
                                      value={spsc.status.id_crm}
                                      onChange={e => spsc.onChangeStatus("id_crm", e.target.value)}
                                    />
                                  </Col>
                                  <Col span={24}>
                                    <span className="description">Внешний ID</span>
                                    <Select
                                      style={{ width: '100%' }}
                                      value={spsc.status.location ?? 'leftTop'}
                                      onChange={(e) => spsc.onChangeStatus("location", e)}
                                      options={[
                                        { value: 'leftTop', label: 'Слева сверху' },
                                        { value: 'rightTop', label: 'Справа сверху' },
                                        { value: 'leftBottom', label: 'Слева снизу' },
                                        { value: 'rightBottom', label: 'Справа снизу' },
                                      ]}
                                    />
                                  </Col>
                                  <Col span={24}>
                                    <span className="description">
                                        Изображение
                                    </span>
                                    {spsc.status.image
                                      ? <Row gutter={[10, 10]}>
                                        <Col xs={24}>
                                          <img src={spsc.status.image.base + spsc.status.image.uri} alt='' style={{maxWidth: '250px'}}/>
                                        </Col>
                                        <Col xs={24}>
                                          <Button
                                            onClick={() => {
                                              deleteFile(spsc.status.image.id)
                                              spsc.status.image = null
                                            }}
                                          >
                                            {i.t('def:deletePicture')}
                                          </Button>
                                        </Col>
                                      </Row>
                                      : <></>
                                    }


                                    <Upload
                                      beforeUpload={(info) => {
                                        spsc.upload(info)
                                        return false
                                      }}
                                      listType="picture-card"
                                      className="photo-uploader"
                                      maxCount={1}
                                    >
                                      <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>{i.t('def:image.upload')}</div>
                                      </div>
                                    </Upload>
                                  </Col>
                                </Row>
                                {/*<div className="select-main_block">*/}
                                {/*    <Row justify="space-around" align="middle" className="select-main">*/}
                                {/*        <Col span={20}>*/}
                                {/*            <span className="description">*/}
                                {/*                {i.t('product:isHome')}*/}
                                {/*            </span>*/}
                                {/*        </Col>*/}
                                {/*        <Col span={4} className="right">*/}
                                {/*            <Switch*/}
                                {/*                checked={spsc.status.viewmain && spsc.status.viewmain > 0 && true}*/}
                                {/*                onChange={e => spsc.onChangeSwitch("viewmain", e)}*/}
                                {/*                size="small"*/}
                                {/*            />*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}

                                {/*    <Row justify="space-around" align="middle" className="select-main">*/}
                                {/*        <Col span={20}>*/}
                                {/*            <span className="description">*/}
                                {/*                {i.t('product:isCart')}*/}
                                {/*            </span>*/}
                                {/*        </Col>*/}
                                {/*        <Col span={4} className="right">*/}
                                {/*            <Switch*/}
                                {/*                checked={spsc.status.viewcart && spsc.status.viewcart > 0 && true}*/}
                                {/*                onChange={e => spsc.onChangeSwitch("viewcart", e)}*/}
                                {/*                size="small"*/}
                                {/*            />*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}
                                {/*</div>*/}

                                <Col span={24}>
                                    <Row gutter={[15, 15]}>
                                        <Col span={24}><Button htmlType="submit" block type="primary">
                                            {i.t('def:actions.save')}
                                        </Button></Col>
                                        {status.id > 3 && <Col span={24}><Button block type="primary" danger onClick={() => spsc.deleteStatus(status.id)}>
                                            {i.t('def:actions.remove')}
                                        </Button></Col>}
                                    </Row>
                                </Col>

                            </>
                            : <Col span={24}>
                                <p className="order_name" style={{ color: "#" + status.colorhex }}>{ status.name }</p>
                            </Col>
                        }
                    </Row>
                </form>
            </Col>
        </>
    )
})

const ShopProductStatuses = observer(() => {

    useEffect(() => {
        spsc.getAllStatuses()
    }, [])

    return(
        <>
            <Helmet>
                <title>{i.t('product:statuses')}</title>
            </Helmet>
            <Row justify="space-around" align="middle" className="title_page">
                <Col span={24}>
                    <Breadcrumb>
                        <Breadcrumb.Item href={"/"}>{i.t('status:main')}</Breadcrumb.Item>
                        <Breadcrumb.Item href={"/shop"}>{i.t('status:shop')}</Breadcrumb.Item>
                        <Breadcrumb.Item>{i.t('status:statuses')}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 18 }}>
                    <h1>{i.t('product:statuses')}</h1>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 6 }} className="right">
                    <Button type="primary" onClick={() => spsc.createStatus()}>
                        {i.t('product:createStatus')}
                    </Button>
                </Col>
            </Row>

            <Row gutter={[20, 20]}>
                {spsc.statuses.map(item => <Status status={item} />)}
            </Row>
        </>
    )
})

export default ShopProductStatuses