import { observer } from 'mobx-react-lite'

import { ReactComponent as IconCheck } from './icons/check.svg'
import { ReactComponent as IconFood } from './icons/food.svg'
import { ReactComponent as IconHelp } from './icons/help.svg'
import { ReactComponent as IconLocation } from './icons/location.svg'
import { ReactComponent as IconLocationDelivery } from './icons/location_delivery.svg'
import { ReactComponent as IconOrders } from './icons/orders.svg'
import { ReactComponent as IconSales } from './icons/sales.svg'
import { ReactComponent as IconStores } from './icons/stores.svg'
import { ReactComponent as IconWalletLarge } from './icons/wallet_large.svg'

import { ReactComponent as IconAbout } from './icons/about.svg'
import { ReactComponent as IconBag } from './icons/bag.svg'
import { ReactComponent as IconCalculator } from './icons/calculator.svg'
import { ReactComponent as IconCalendar } from './icons/calendar.svg'
import { ReactComponent as IconCall } from './icons/call.svg'
import { ReactComponent as IconCatalog } from './icons/catalog.svg'
import { ReactComponent as IconClock } from './icons/clock.svg'
import { ReactComponent as IconComment } from './icons/comment.svg'
import { ReactComponent as IconContacts } from './icons/contacts.svg'
import { ReactComponent as IconData } from './icons/data.svg'
import { ReactComponent as IconDiscount } from './icons/discount.svg'
import { ReactComponent as IconEt } from './icons/et.svg'
import { ReactComponent as IconFb } from './icons/fb.svg'
import { ReactComponent as IconFeedback } from './icons/feedback.svg'
import { ReactComponent as IconFile } from './icons/file.svg'
import { ReactComponent as IconGift } from './icons/gift.svg'
import { ReactComponent as IconHome } from './icons/home.svg'
import { ReactComponent as IconInstagram } from './icons/instagram.svg'
import { ReactComponent as IconLink } from './icons/link.svg'
import { ReactComponent as IconLock } from './icons/lock.svg'
import { ReactComponent as IconMail } from './icons/mail.svg'
import { ReactComponent as IconMessages } from './icons/messages.svg'
import { ReactComponent as IconMessenger } from './icons/messenger.svg'
import { ReactComponent as IconMonitor } from './icons/monitor.svg'
import { ReactComponent as IconNewsActive } from './icons/news_active.svg'
import { ReactComponent as IconNotifications } from './icons/notifications.svg'
import { ReactComponent as IconOk } from './icons/ok.svg'
import { ReactComponent as IconProfile } from './icons/profile.svg'
import { ReactComponent as IconQuestionnaire } from './icons/questionnaire.svg'
import { ReactComponent as IconSaved } from './icons/saved.svg'
import { ReactComponent as IconScooter } from './icons/scooter.svg'
import { ReactComponent as IconStar } from './icons/star.svg'
import { ReactComponent as IconTG } from './icons/tg.svg'
import { ReactComponent as IconThisApp } from './icons/this_app.svg'
import { ReactComponent as IconThumbsUp } from './icons/thumbs_up.svg'
import { ReactComponent as IconViber } from './icons/viber.svg'
import { ReactComponent as IconVK } from './icons/vk.svg'
import { ReactComponent as IconWalletArrowUp } from './icons/wallet_arrow_up.svg'
import { ReactComponent as IconWhatsup } from './icons/whatsup.svg'
import { ReactComponent as IconYoutube } from './icons/youtube.svg'
import { ReactComponent as IconHome2 } from './icons/home2.svg'
import { ReactComponent as IconCatalog2 } from './icons/catalog2.svg'
import { ReactComponent as IconBag2 } from './icons/bag2.svg'
import { ReactComponent as IconSaved2 } from './icons/saved2.svg'
import { ReactComponent as IconProfile2 } from './icons/profile2.svg'
import { ReactComponent as IconReferral } from './icons/referral.svg'
import { ReactComponent as IconCards } from './icons/cards.svg'
import { ReactComponent as IconCoupon } from './icons/coupon.svg'
import { ReactComponent as IconLegalEntities } from './icons/legal_entities.svg'

export const SelectIcon = observer(({ active, referal_link }: any) => {

    const icons = [
        { value: 'ic_menu_check', file: <IconCheck /> },
        { value: 'ic_menu_food', file: <IconFood /> },
        { value: 'ic_menu_help', file: <IconHelp /> },
        { value: 'ic_menu_location', file: <IconLocation /> },
        { value: 'ic_menu_location_delivery', file: <IconLocationDelivery /> },
        { value: 'ic_menu_orders', file: <IconOrders /> },
        { value: 'ic_menu_sales', file: <IconSales /> },
        { value: 'ic_menu_stores', file: <IconStores /> },
        { value: 'ic_menu_wallet_large', file: <IconWalletLarge /> },
        { value: 'ic_menu_about', file: <IconAbout /> },
        { value: 'ic_menu_bag', file: <IconBag /> },
        { value: 'ic_menu_calculator', file: <IconCalculator /> },
        { value: 'ic_menu_calendar', file: <IconCalendar /> },
        { value: 'ic_menu_call', file: <IconCall /> },
        { value: 'ic_menu_catalog', file: <IconCatalog /> },
        { value: 'ic_menu_clock', file: <IconClock /> },
        { value: 'ic_menu_comment', file: <IconComment /> },
        { value: 'ic_menu_contacts', file: <IconContacts /> },
        { value: 'ic_menu_data', file: <IconData /> },
        { value: 'ic_menu_discount', file: <IconDiscount /> },
        { value: 'ic_menu_et', file: <IconEt /> },
        { value: 'ic_menu_fb', file: <IconFb /> },
        { value: 'ic_menu_feedback', file: <IconFeedback /> },
        { value: 'ic_menu_file', file: <IconFile /> },
        { value: 'ic_menu_gift', file: <IconGift /> },
        { value: 'ic_menu_home', file: <IconHome /> },
        { value: 'ic_menu_instagram', file: <IconInstagram /> },
        { value: 'ic_menu_link', file: <IconLink /> },
        { value: 'ic_menu_lock', file: <IconLock /> },
        { value: 'ic_menu_mail', file: <IconMail /> },
        { value: 'ic_menu_messages', file: <IconMessages /> },
        { value: 'ic_menu_messenger', file: <IconMessenger /> },
        { value: 'ic_menu_monitor', file: <IconMonitor /> },
        { value: 'ic_menu_news_active', file: <IconNewsActive /> },
        { value: 'ic_menu_notifications', file: <IconNotifications /> },
        { value: 'ic_menu_ok', file: <IconOk /> },
        { value: 'ic_menu_profile', file: <IconProfile /> },
        { value: 'ic_menu_questionnaire', file: <IconQuestionnaire /> },
        { value: 'ic_menu_saved', file: <IconSaved /> },
        { value: 'ic_menu_scooter', file: <IconScooter /> },
        { value: 'ic_menu_star', file: <IconStar /> },
        { value: 'ic_menu_tg', file: <IconTG /> },
        { value: 'ic_menu_this_app', file: <IconThisApp /> },
        { value: 'ic_menu_thumbs_up', file: <IconThumbsUp /> },
        { value: 'ic_menu_viber', file: <IconViber /> },
        { value: 'ic_menu_vk', file: <IconVK /> },
        { value: 'ic_menu_wallet_arrow_up', file: <IconWalletArrowUp /> },
        { value: 'ic_menu_whatsapp', file: <IconWhatsup /> },
        { value: 'ic_menu_youtube', file: <IconYoutube /> },
        { value: 'ic_menu_home_2', file: <IconHome2 /> },
        { value: 'ic_menu_catalog_2', file: <IconCatalog2 /> },
        { value: 'ic_menu_bag_2', file: <IconBag2 /> },
        { value: 'ic_menu_saved_2', file: <IconSaved2 /> },
        { value: 'ic_menu_profile_2', file: <IconProfile2 /> },
        { value: 'ic_menu_referral', file: <IconReferral /> },
        { value: 'ic_menu_cards', file: <IconCards /> },
        { value: 'ic_menu_coupon', file: <IconCoupon /> },
        { value: 'ic_menu_legal_entities', file: <IconLegalEntities /> },
    ]

    return(
        <div className="icon_select">
            {icons.map(el => <div
                className={el.value === active ? 'active' : ''}
                onClick={() => referal_link(el.value)}
            >{el.file}</div>)}
        </div>
    )
})