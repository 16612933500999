import { observer } from 'mobx-react-lite'
import __users from './users.controller'
import React, { FormEvent, useEffect, useState } from "react";
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import { Button, DatePicker, Space, Table, Tooltip, Row, Col, Segmented, InputNumber, Modal, Input, Tag, Divider } from 'antd'
import { SettingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import { parseProject } from '../../helpers/project.controller'
import { FilterHeader } from "../../helpers/FilterHeader";
import validator from "validator";
import isNumeric = validator.isNumeric;

dayjs.extend(utc)

export const Users = observer(() => {

    useEffect(() => {
        __users.getList()
    }, [])

    const buttons = [
        { title: i.t('def:actions.create'), type: 'dashed', href: '/users/0' },
        { title: i.t('users:excelExport'), type: 'primary', click: () => __users.exportUsers('xlsx') },
        { title: i.t('users:csvExport'), type: 'primary', click: () => __users.exportUsers('csv') }
    ]

    const columns = [
        {
            title: "ID",
            key: 'id',
            dataIndex: 'id',
        },
        {
            title: i.t('users:name'),
            key: 'name',
            render: (record: any) => `${record.firstname ?? ''} ${record.lastname ?? ''} ${record.surname ?? ''}`
        },
        {
            title: i.t('users:email'),
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: i.t('users:phone'),
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: i.t('users:count_payed_orders'),
            dataIndex: 'count_payed_orders',
            render: (date: number) => date
        },
        {
            title: i.t('users:last_order_date'),
            dataIndex: 'last_order_date',
            render: (date: any) => date ? dayjs(date).format("DD.MM.YYYY") : ''
        },
        {
            title: i.t('users:birthday'),
            dataIndex: 'birthday',
            render: (date: any) => date > 0 ? dayjs(date*1000).format("DD.MM.YYYY") : ''
        },
        {
            title: i.t('points:actions'),
            key: 'action',
            render: (text: any, record: any) => (
                <>
                    <Space>
                        <Tooltip title={i.t('def:actions.edit')}>
                            <Button
                                type="dashed"
                                icon={<SettingOutlined />}
                                href={`/users/${record.id}`}
                            />
                        </Tooltip>
                    </Space>
                </>
            ),
        },
    ]

    return(
        <>
            <Titles
                title={i.t('users:listUsers')}
                buttons={buttons}
                breadcrumb={[
                    {title: i.t('users:listUsers')}
                ]}
            />

            <FilterHeader
                fields={[
                    {
                        label: i.t('users:name'),
                        value: __users.list_filter.name ?? '',
                        type: 'string',
                        onChange: (e: string) => __users.setFilter('name', e)
                    },
                    {
                        label: i.t('users:phone'),
                        value: __users.list_filter.phone ?? '',
                        type: 'string',
                        onChange: (e: string) => __users.setFilter('phone', e)
                    },
                    {
                        label: i.t('users:email'),
                        value: __users.list_filter.email ?? '',
                        type: 'string',
                        onChange: (e: string) => __users.setFilter('email', e)
                    }
                ]}
            />

            <Table
                columns={columns}
                dataSource={__users.users}
                rowKey="id"
                pagination={{
                    total: __users.list.count,
                    current: __users.list.offset === 0 ? 1 : Math.floor(__users.list.offset / __users.list.limit) + 1,
                    pageSize: __users.list.limit,
                    onChange: (page, pageSize) => {
                        __users.list.offset = page > 1 ? (page * __users.list.limit) - __users.list.limit : 0
                        console.log(__users.list.offset)
                        __users.getList()
                    },
                    onShowSizeChange: (current, size) => {
                        __users.list.limit = size
                        __users.list.offset = 0
                        __users.getList()
                    }
                }}
            />
        </>
    )
})

export const UserDetail = observer(() => {

    const [currency, setCurrency] = useState('')

    parseProject('CURRENCY_CODE').then(res => setCurrency(res))

    let { userID }: any = useParams()

    const columns_1 = [
        {
            title: i.t('def:balance.user.table_created_at'),
            key: 'created_at',
            dataIndex: 'created_at',
            render: (e: any) => dayjs(e).format("DD.MM.YYYY")
        },
        {
            title: i.t('def:balance.user.table_balance'),
            key: 'balance',
            dataIndex: 'balance',
            render: (e: Number) => e > 0 ? <p className="green">{e}</p> : <p className="red">{e}</p>
        },
        {
            title: i.t('def:balance.user.table_order'),
            key: 'order_id',
            dataIndex: 'order_id',
            render: (e: any) => e ? <a href={`/shop/orders/${e}`}>#{e}</a> : ""
        }
    ]

    const columns_2 = [
        {
            title: i.t('def:balance.user.table_combustion_date'),
            key: 'combustion_date',
            dataIndex: 'combustion_date',
            render: (e: any) => e ? dayjs(e).format("DD.MM.YYYY") : ''
        },
        {
            title: i.t('def:accrued'),
            key: 'balance',
            dataIndex: 'balance',
            render: (e: Number) => e > 0 ? <p className="green">{e}</p> : <p className="red">{e}</p>
        },
    ]

    useEffect(() => {
        return () => {
            __users.getOne(userID)

        }
    }, [])

    return(
        <>
            <form onSubmit={(e: FormEvent) => __users.save(e)}>
                <Titles
                  title={i.t('user:info')}
                  buttons={[
                      { title: i.t('def:actions.save'), type: 'primary', htmlType: 'submit' },
                      { title: i.t('def:actions.backList'), type: 'dashed', href: `/users` },
                      {
                          title: __users.user.is_blocked ? i.t('def:actions.userUnBlock') : i.t('def:actions.userBlock'),
                          type: 'dashed',
                          click: () => __users.blockUser(__users.user.id)
                      }
                  ]}
                  breadcrumb={[
                      {title: i.t('users:listUsers'), href: '/users'},
                      {title: i.t('user:info')},
                  ]}
                />

                <Row className="user_detail_cart" gutter={[15, 15]}>
                    <Col span={8} className="detail_element">
                        <p className="detail_title">ID</p>
                        <p className="detail_value">#{__users.user.id}</p>
                    </Col>
                    <Col span={8} className="detail_element">
                        <p className="detail_title">E-mail</p>
                        <Input
                          value={__users.user.email}
                          onChange={(e: any) => __users.user.email = e.target.value}
                        />
                    </Col>
                    <Col span={8} className="detail_element">
                        <p className="detail_title">{i.t('user:phone')}</p>
                        <Input
                          value={__users.user.phone}
                          onChange={(e: any) => __users.user.phone = e.target.value}
                        />
                    </Col>
                    <Col span={8} className="detail_element">
                        <p className="detail_title">{i.t('user:name')}</p>
                        <Input
                          value={__users.user.firstname}
                          onChange={(e: any) => __users.user.firstname = e.target.value}
                        />
                    </Col>
                    <Col span={8} className="detail_element">
                        <p className="detail_title">{i.t('user:lastname')}</p>
                        <Input
                          value={__users.user.lastname}
                          onChange={(e: any) => __users.user.lastname = e.target.value}
                        />
                    </Col>
                    <Col span={8} className="detail_element">
                        <p className="detail_title">{i.t('user:middle')}</p>
                        <Input
                          value={__users.user.surname}
                          onChange={(e: any) => __users.user.surname = e.target.value}
                        />
                    </Col>
                    <Col span={12} className="detail_element">
                        <p className="detail_title">{i.t('user:birthday')}</p>
                        <DatePicker
                          value={__users.user.birthday ? dayjs(__users.user.birthday * 1000).utc() : null}
                          onChange={e => {
                              let date = e.startOf('day')
                              console.log(`${date.unix()}`)
                              __users.user.birthday = date.unix()
                          }}
                        />
                    </Col>
                    <Divider />
                    <Col span={12} className="detail_element">
                        <p className="detail_title">{i.t('def:password_one')}</p>
                        <Input.Password
                          value={__users.password.password}
                          onChange={e => __users.password.password = e.target.value}
                        />
                    </Col>
                    <Col span={12} className="detail_element">
                        <p className="detail_title">{i.t('def:password_repeat')}</p>
                        <Input.Password
                          value={__users.password.password_repeat}
                          onChange={e => __users.password.password_repeat = e.target.value}
                        />
                    </Col>
                    <Divider />
                    <Col span={12} className="detail_element">
                        <p className="detail_title">{i.t('def:Auth code')}</p>
                        <Input
                          maxLength={4}
                          value={__users.user.auth_code ?? ''}
                          onChange={e => {
                              const inputValue = e.target.value
                              if (/^\d{0,4}$/.test(inputValue)) {
                                  console.log(inputValue)
                                  __users.user.auth_code = inputValue
                              }
                          }}
                        />
                    </Col>
                </Row>
            </form>

                {
                    __users.user.id > 0
                    ? <>
                          <ListCompaniesData />

                          <Row className="user_orders_cart">
                              <Col span={24}>
                                  <p className="orders">{i.t('def:orders_el')}</p>
                              </Col>
                              <Col span={6}>
                                  <p className="detail_title">{i.t('def:count')}</p>
                                  <p className="detail_value">{__users.user.orders.count ?? 0}</p>
                              </Col>
                              <Col span={6}>
                                  <p className="detail_title">{i.t('def:sum')}</p>
                                  <p className="detail_value">{currency && new Intl.NumberFormat('ru-RU', {style: 'currency', currency: currency}).format(__users.user.orders.sum ?? 0)}</p>
                              </Col>
                              <Col span={6}>
                                  <p className="detail_title">{i.t('def:payed')}</p>
                                  <p className="detail_value">{__users.user.orders.count_pay ?? 0}</p>
                              </Col>
                              <Col span={6}>
                                  <p className="detail_title">{i.t('def:pay')}</p>
                                  <p className="detail_value">{currency && new Intl.NumberFormat('ru-RU', {style: 'currency', currency: currency}).format(__users.user.orders.sum_pay ?? 0)}</p>
                              </Col>
                          </Row>

                          <Row className="user_detail_cart dash" gutter={[15, 15]}>
                              <Col span={24}>
                                  <p className="orders">{i.t('def:bonuses')}</p>
                              </Col>
                              <Col span={24}>
                                  <p className="orders_all">{i.t('def:size_bonuses')}: <span>{__users.bonus_balance}</span></p>
                              </Col>
                              <Col span={24}>
                                  <br/><br/>
                                  <form onSubmit={(event) => __users.addBonuses(event)}>
                                      <Row gutter={[20, 20]}>
                                          <Col span={24}>
                                              <p className="detail_value">{i.t('def:actions.add')}</p>
                                          </Col>
                                          <Col span={4}>
                                              <InputNumber
                                                style={{ width: '100%' }}
                                                value={__users.bonuses_add}
                                                onChange={(e) => __users.bonuses_add = e ? e : 1}
                                                min={1}
                                                required
                                              />
                                          </Col>
                                          <Col span={4}>
                                              <DatePicker
                                                style={{ width: '100%' }}
                                                value={__users.bonuses_add_combustion_date ? dayjs(__users.bonuses_add_combustion_date * 1000).utc() : null}
                                                placeholder={i.t('def:combustion_date')}
                                                onChange={e => {
                                                    if(e) {
                                                        __users.bonuses_add_combustion_date = dayjs(e).unix()
                                                    }
                                                }}
                                              />
                                          </Col>
                                          <Col span={8}>
                                              <Button htmlType="submit">{i.t('def:actions.add')}</Button>
                                          </Col>
                                      </Row>
                                  </form>
                                  <br/><br/>
                              </Col>
                              <Col span={24}>
                                  <br/><br/>
                                  <form onSubmit={(event) => __users.deleteBonuses(event)}>
                                      <Row gutter={[20, 20]}>
                                          <Col span={24}>
                                              <p className="detail_value">{i.t('def:actions.remove')}</p>
                                          </Col>
                                          <Col span={4}>
                                              <InputNumber
                                                style={{ width: '100%' }}
                                                value={__users.bonuses_delete}
                                                onChange={(e) => __users.bonuses_delete = e ? e : 1}
                                                min={1}
                                                required
                                              />
                                          </Col>
                                          <Col span={8}>
                                              <Button htmlType="submit">{i.t('def:actions.remove')}</Button>
                                          </Col>
                                      </Row>
                                  </form>
                                  <br/><br/>
                              </Col>
                              <Col span={24}>
                                  <Segmented
                                    options={[
                                        {label: i.t('def:detailing'), value: 1},
                                        {label: i.t('def:validity'), value: 2},
                                    ]}
                                    value={__users.bonus_table}
                                    onChange={(e) => __users.setTypeTable(e)}
                                  />
                              </Col>
                              <Col span={24}>
                                  {__users.bonus_table == 1
                                    ? <>
                                        <Table
                                          columns={columns_1}
                                          dataSource={__users.bonuses}
                                          rowKey="id"
                                          pagination={{
                                              total: __users.bonuses_total,
                                              pageSize: __users.bonuses_limit,
                                              onChange: (page, pageSize) => {
                                                  __users.bonuses_page = page
                                                  __users.getHistory()
                                              },
                                              onShowSizeChange: (current, size) => {
                                                  __users.bonuses_limit = size
                                                  __users.bonuses_page = 1
                                                  __users.getHistory()
                                              }
                                          }}
                                        />
                                    </>
                                    : <>
                                        <Table
                                          columns={columns_2}
                                          dataSource={__users.bonuses}
                                          rowKey="id"
                                          pagination={{
                                              total: __users.bonuses_total,
                                              pageSize: __users.bonuses_limit,
                                              onChange: (page, pageSize) => {
                                                  __users.bonuses_page = page
                                                  __users.getHistory(2)
                                              },
                                              onShowSizeChange: (current, size) => {
                                                  __users.bonuses_limit = size
                                                  __users.bonuses_page = 1
                                                  __users.getHistory(2)
                                              }
                                          }}
                                        />
                                    </>
                                  }
                              </Col>
                          </Row>
                      </>
                    : <></>
                }
        </>
    )
})

const ListCompaniesData = observer(() => {
    return(
      <>
        <Row className="user_detail_cart dash" gutter={[15, 15]}>
            <Col span={12}>
                <p className="orders">{i.t('def:User companies list')}</p>
            </Col>
            <Col span={12} className="right">
                <Button type="dashed" onClick={() => __users.openSearchCompany()}>{i.t('def:actions.add')}</Button>
            </Col>
            <Col span={24}>
                {__users.user.companies.map((el: any) => (
                  <Col span={24}>
                      <Row className="company_search" gutter={[10, 10]}>
                          <Col span={24}>
                              {el.type === 2 ? <Tag>{i.t('def:Legal entity')}</Tag> : <Tag>{i.t('def:Individual entrepreneur')}</Tag>}
                              <Tag color={el.status ? "success" : "error"}>{el.status ? "Действующее" : "Не действующее"}</Tag>
                          </Col>
                          <Col span={24}>
                              {el.type === 2
                                ? <p>{el.name}</p>
                                : <p>{el.fio}</p>
                              }
                          </Col>
                          <Col span={24}>
                              <p>ИНН/ОГРН: {el.inn} / {el.ogrn}</p>
                          </Col>
                          <Col span={24}>
                              <p>Юридический адрес: {el.address}</p>
                          </Col>
                          <Col span={24}>
                              <Button size="small" type="dashed" danger onClick={() => __users.deleteCompanyFromUser(el.id)}>{i.t('def:actions.remove')}</Button>
                          </Col>
                      </Row>
                  </Col>
                ))}
            </Col>
        </Row>

        <Modal open={__users.modalCompany.open} title={i.t('def:Add company')} onCancel={() => __users.closeSearchCompany()} footer={false}>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Space>
                        <Input
                            value={__users.modalCompany.inn ?? ''}
                            onChange={(e: any) => __users.modalCompany.inn = e.target.value}
                        />
                        <Button type="dashed" onClick={() => __users.searchCompany()}>{i.t('def:actions.search')}</Button>
                    </Space>
                </Col>
                <Col span={24}>
                    <>
                        <Row gutter={[10, 10]}>
                            {__users.modalCompany.list.map((el: any) => (
                              <Col span={24}>
                                  <Row className="company_search" gutter={[10, 10]}>
                                      <Col span={24}>
                                          {el.type === 2 ? <Tag>{i.t('def:Legal entity')}</Tag> : <Tag>{i.t('def:Individual entrepreneur')}</Tag>}
                                          <Tag color={el.status ? "success" : "error"}>{el.status ? "Действующее" : "Не действующее"}</Tag>
                                      </Col>
                                      <Col span={24}>
                                          {el.type === 2
                                            ? <p>{el.name}</p>
                                            : <p>{el.fio}</p>
                                          }
                                      </Col>
                                      <Col span={24}>
                                          <p>ИНН/ОГРН: {el.inn} / {el.ogrn}</p>
                                      </Col>
                                      {el.kpp &&
                                        <Col span={24}>
                                            <p>КПП: {el.kpp}</p>
                                        </Col>
                                      }
                                      {el.address &&
                                        <Col span={24}>
                                            <p>Юридический адрес: {el.address}</p>
                                        </Col>
                                      }
                                      <Col span={24}>
                                          <Button size="small" type="dashed" onClick={() => __users.addCompanyToUser(el)}>{i.t('def:actions.select')}</Button>
                                      </Col>
                                  </Row>
                              </Col>

                            ))}
                        </Row>
                    </>
                </Col>
            </Row>
        </Modal>
      </>
    )
})