import { makeAutoObservable } from 'mobx'
import { openApi, request } from '../../../../helpers/request'

class shopProductStatusesController {
    constructor() {
        makeAutoObservable(this)
    }

    statuses = []
    status = {}
    image = null

    createStatus() {
        this.clearStatus()
        this.status = {
            id: 0,
            name: '',
            colorhex: '7264D6'
        }
        this.statuses.unshift(this.status)
    }

    onSubmit(e) {
        e.preventDefault()

        let data = new FormData()
        data.append('name', this.status.name)
        data.append('colorhex', this.status.colorhex)
        data.append('viewcart', this.status.viewcart ? this.status.viewcart : 0)
        data.append('viewmain', this.status.viewmain ? this.status.viewmain : 0)
        data.append('location', this.status.location ? this.status.location : 'leftTop')
        data.append('id_crm', this.status.id_crm ? this.status.id_crm : '')

        if(!this.status.image)
            data.append('delete_image', '1')

        if(this.image) data.append('image', this.image)

        let statusID = this.status.id && this.status.id > 0 ? this.status.id : 0

        openApi('post', `/product_types/${statusID}`, data)
            .then((result) => {
                console.log(result)
                this.getAllStatuses()
                this.clearStatus()
            })
          .catch(error => console.log(error.response))

        // request('post', '/shop/status/' + statusID + '/', data, true).then(
        //     (response) => {
        //         console.log(response)
        //         this.getAllStatuses()
        //         this.clearStatus()
        //     }
        // )
    }

    clearStatus() {
        this.status = {}
    }

    onChangeSwitch(key, value) {
        let e = 0
        if (value) {
            e = 1
        }
        this.onChangeStatus(key, e)
    }

    selectStatus(statusID) {
        openApi('get', `/products/types/${statusID}`)
          .then((success) => {
              console.log(success.data)
              this.status = success.data
          })
    }

    onChangeStatus(key, value) {
        this.status[key] = value.replace('#', '')
        console.log(this.status[key])
    }

    getAllStatuses() {
        openApi('get', '/products/types')
            .then((success) => {
                this.statuses = success.data
            })
    }

    deleteStatus(statusID) {
        request('delete', '/shop/status/' + statusID + '/', {}, true)
            .then((response) => {
                this.getAllStatuses()
            }
        )
    }

    upload(image) {
        this.image = image
        return false
    }
}

export default new shopProductStatusesController()
