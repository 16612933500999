import { observer } from 'mobx-react-lite'
import { Row, Col, Input, Divider, InputNumber, Select, TreeSelect, Button } from 'antd'
import { SwitchBlock } from '../../../../components/SwitchBlock'
import i from '../../../../translations/i'
import { __product } from '../__product'
import { runInAction } from 'mobx'
import React, { useEffect } from 'react'
import unitController from '../../../../modules/shop/units/unit.controller'
import shopProductStatusesController from '../../../../modules/shop/product/controllers/shopProductStatuses.controller'
import { DeleteOutlined } from '@ant-design/icons'
import __admin from "../../../settings/admin/__admin";
import { storeLocal } from "../../../../helpers/stores";

export const ProductMain = observer(() => {

    useEffect(() => {
        __product.getCategoryTree()
        unitController.get(0, true)
        shopProductStatusesController.getAllStatuses()
        __admin.getList()
    }, [])

    return(
        <>
            <Row gutter={[20, 20]} align="bottom">
                <Col span={24}>
                    <SwitchBlock array={[
                        { label: i.t('product:active'), click: (e: any) => __product.item.view = e, value: __product.item.view },
                        { label: i.t('product:weightGoods'), click: (e: any) => __product.item.weight_goods = e, value: __product.item.weight_goods },
                        { label: i.t('product:float'), click: (e: any) => __product.item.view_per_unit = e, value: __product.item.view_per_unit },
                        { label: i.t('product:allowAddingToCart'), click: (e: any) => __product.item.allow_adding_to_cart = e, value: __product.item.allow_adding_to_cart, disabled: storeLocal.get('role') === 4 },
                        { label: i.t('product:allowTransitionToProductCard'), click: (e: any) => __product.item.allow_transition_to_product_card = e, value: __product.item.allow_transition_to_product_card, disabled: storeLocal.get('role') === 4 },
                        { label: i.t('product:nutritional_value_100_gr'), click: (e: any) => __product.item.nutritional_value_100_gr = e, value: __product.item.nutritional_value_100_gr },
                    ]} />
                </Col>
                <Divider />
                <Col span={24}>
                    <label className="formLabel">{i.t('product:parent')}</label>
                    {__product.tree.length
                      ? <TreeSelect
                        style={{ width: '100%' }}
                        treeData={__product.tree}
                        value={__product.item.categories}
                        treeCheckable={true}
                        onChange={(e: any) => runInAction(() => __product.item.categories = e)}
                        showSearch={false}
                        multiple
                      />
                      : <></>
                    }

                </Col>
                <Divider />
                <Col span={12}>
                    <label className="formLabel">{i.t('product:nameProduct')}</label>
                    <Input
                        value={__product.item.name}
                        onChange={ (e) => runInAction(() => __product.item.name = e.target.value) }
                        required
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:idCrm')}</label>
                    <Input
                        value={__product.item.vendor_code}
                        onChange={ (e) => runInAction(() => __product.item.vendor_code = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:id_crm')}</label>
                    <Input
                        value={__product.item.id_crm}
                        onChange={ (e) => runInAction(() => __product.item.id_crm = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:price')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={__product.item.price}
                        onChange={ (e) => runInAction(() => __product.item.price = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:oldPrice')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={__product.item.old_price}
                        onChange={ (e) => runInAction(() => __product.item.old_price = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:count')}</label>
                    <InputNumber
                        style={{ width: '100%' }}
                        value={__product.item.count}
                        onChange={ (e) => runInAction(() => __product.item.count = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:unit')}</label>
                    <Select
                        style={{ width: '100%' }}
                        options={[
                            { label: '', value: null },
                            ...unitController.list.map((el) => ({
                                label: `${el.name} (${el.description})`,
                                value: el.id
                            }))
                        ]}
                        value={__product.item.unit_id}
                        onChange={ (e) => runInAction(() => __product.item.unit_id = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:type')}</label>
                    <Select
                        style={{ width: '100%' }}
                        options={shopProductStatusesController.statuses.map((el: any) => ({
                            label: el.name,
                            value: el.id
                        }))}
                        value={__product.item.types}
                        onChange={(e) => runInAction(() => __product.item.types = e) }
                        mode="multiple"
                        showSearch={false}
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:step')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.step}
                      onChange={ (e) => runInAction(() => __product.item.step = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:cart_limit')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.cart_limit}
                      onChange={ (e) => runInAction(() => __product.item.cart_limit = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:free_text')}</label>
                    <Input
                      value={__product.item.free_text}
                      onChange={ (e) => runInAction(() => __product.item.free_text = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('product:buy_button')}</label>
                    <Input
                      value={__product.item.buy_button}
                      onChange={ (e) => runInAction(() => __product.item.buy_button = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:Sorting in an Order')}</label>
                    <Input
                        value={__product.item.sorting_order}
                        onChange={ (e) => runInAction(() => __product.item.sorting_order = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:Volume')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.volume}
                      onChange={ (e) => runInAction(() => __product.item.volume = e) }
                      addonAfter={i.t('def:Centimeters')}
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:Weight')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.weight}
                      onChange={ (e) => runInAction(() => __product.item.weight = e) }
                      addonAfter={i.t('def:Grams')}
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:Calories')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.calories}
                      onChange={ (e) => runInAction(() => __product.item.calories = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:Proteins')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.proteins}
                      onChange={ (e) => runInAction(() => __product.item.proteins = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:Fats')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.fats}
                      onChange={ (e) => runInAction(() => __product.item.fats = e) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:Carbohydrates')}</label>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={__product.item.carbohydrates}
                      onChange={ (e) => runInAction(() => __product.item.carbohydrates = e) }
                    />
                </Col>

                <Divider />

                <Col span={6}>
                    <label className="formLabel">{i.t('def:seo.name_encode')}</label>
                    <Input
                      value={__product.item.name_encode}
                      onChange={ (e) => runInAction(() => __product.item.name_encode = e.target.value.replace(/[^a-zA-Z0-9_]/g, '')) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:seo.title')}</label>
                    <Input
                      value={__product.item.seo_title}
                      onChange={ (e) => runInAction(() => __product.item.seo_title = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:seo.description')}</label>
                    <Input
                      value={__product.item.seo_description}
                      onChange={ (e) => runInAction(() => __product.item.seo_description = e.target.value) }
                    />
                </Col>
                <Col span={6}>
                    <label className="formLabel">{i.t('def:seo.keywords')}</label>
                    <Input
                      value={__product.item.seo_keywords}
                      onChange={ (e) => runInAction(() => __product.item.seo_keywords = e.target.value) }
                    />
                </Col>

                <Divider />

                <Col span={24}>
                    <Row gutter={[10, 10]}>
                        <Col span={24}>
                            <label className="formLabel">{i.t('product:barcode')}</label>
                        </Col>
                        {__product.item.barcodes.map((el: any, i: number) => (
                            <Col span={8}>
                                <Row gutter={[5, 5]}>
                                    <Col span={20}>
                                        <Input
                                            value={el.value}
                                            onChange={(e: any) => __product.item.barcodes[i].value = e.target.value}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Button
                                            icon={<DeleteOutlined />}
                                            onClick={() => __product.item.barcodes.splice(i, 1)}
                                            type="dashed"
                                            danger
                                        />
                                    </Col>
                                </Row>

                            </Col>
                        ))}
                        <Col span={24}>
                            <Button type="dashed" onClick={() => __product.addBarcodeToItem()}>{i.t('def:actions.add')}</Button>
                        </Col>
                    </Row>
                </Col>
                <Divider />
                <Col span={24}>

                    <Row gutter={[20, 20]}>
                        <Col span={24}>
                            <label className="formLabel">{i.t('def:Tags')}</label>
                        </Col>
                        <Col span={24}>
                            <p className="tags_p">
                                {__product.item.tags.map((tag: string, index: number) => (
                                  <>
                                      <span className="tag" onClick={() => __product.deleteTag(tag)}>{tag}</span>
                                  </>
                                ))}

                            </p>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[20, 20]}>
                                <Col span={12}>
                                    <Input
                                      value={__product.new_tag ?? ''}
                                      placeholder={i.t('def:Add tag')}
                                      onChange={(e: any) => __product.new_tag = e.target.value}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Button
                                      onClick={() => __product.addTag()}
                                      disabled={!__product.new_tag}
                                    >
                                        {i.t("def:Add tag")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <br/><br/>


                </Col>
                {__admin.adminList.length ? <>
                    <Divider />
                    <Col span={24}>
                        <label className="formLabel">{i.t('def:seller')}</label>
                        <Select
                          options={[
                              { label: '', value: null },
                              ...__admin.adminList.filter((admin: any) => admin.right.rights === 4).map((admin, index) => ({
                                  label: `#${admin.id} - ${admin.firstname ?? ''} ${admin.lastname ?? ''} (${admin.email})`,
                                  value: admin.id
                              }))
                          ]}
                          value={(__product.item.seller && __product.item.seller.manager_id) ? __product.item.seller.manager_id : null}
                          style={{ width: '100%' }}
                          onChange={(e: number | null) => {
                              __product.item.seller = { manager_id: e }
                          }}
                          disabled={storeLocal.get('role') === 4}
                        />
                    </Col>
                </> : <></>}
            </Row>
        </>
    )
})