import { Col, Row, Select, Modal } from 'antd'
import React from 'react'
import i from '../../translations/i'
import { ExclamationCircleFilled } from '@ant-design/icons'
import __multiple from './__multiple'
import { observer } from 'mobx-react-lite'
import { Navigation } from '../../layouts/Navigation'

const { confirm } = Modal

interface ActionsInterface {
    label: string
    value: string | number
    action: any
    disabled: boolean
    danger?: boolean
}

interface MultipleActionsInterface {
    size: number
    actions: ActionsInterface[]
}

const showConfirm = (action: any) => {
    confirm({
        title: i.t('def:confirms.delete_elements'),
        icon: <ExclamationCircleFilled />,
        content: i.t('def:confirms.this_action'),
        onOk() {
            action()
        },
        onCancel() {
            console.log('Cancel');
        },
    });
}

export const MultipleActions = observer(() => {

    const onChange = (value: string | number) => {
        const action = __multiple.actions.find((el: ActionsInterface) => el.value === value)
        if(action) {
            if(value === 'delete') {
                showConfirm(action.action)
            } else action.action()
        }
    }

    return(
        <>
            {__multiple.selected.length > 0 &&
                <Row className="selected_elements">
                    <Col xs={{ span: 0 }} md={{ span: 5 }}>

                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 19 }}>
                        <Row justify="space-around" align="middle" className="element">
                            <Col span={12}>
                                {i.t('def:selected_elements')}: {__multiple.selected.length}
                            </Col>
                            <Col span={12} className="right">
                                <Select
                                    placeholder={i.t('def:select_action')}
                                    options={__multiple.actions.map((el: ActionsInterface) => ({ label: el.label, value: el.value, danger: !!el.danger, disabled: el.disabled ?? false }))}
                                    onChange={onChange}
                                    placement="topLeft"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </>
    )
})