import { observer } from 'mobx-react-lite'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import controller from './controller'
import { Button, Pagination, Space, Table, Switch, Row, Col, Input, notification, Upload, Select } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ParamTypes } from '../../../helpers/types.interfaces'
import { SelectArticleCategories, SelectEntity } from "../../../helpers/select_entity/SelectEntity";
import __select from "../../../helpers/select_entity/__select.controller";

export const Calendar = observer(() => {
  const { elementID } = useParams<ParamTypes>()

  useEffect(() => {
    controller.get(elementID)
    __select.getArticleCategories()
  }, [])

  const buttons = [
    { title: i.t('def:actions.backList'), href: `/settings/calendars`, type: 'dashed' },
    { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
  ]

  return (
    <>
      <form onSubmit={(e) => controller.save(e)}>
        <Titles
          title={i.t('def:calendars.titleDetail')}
          buttons={buttons}
        />

        <Row gutter={[20, 20]}>
          <Col span={24}>
            <label>{i.t('def:name')}</label>
            <Input
              name="title"
              placeholder={i.t('def:name')}
              value={controller.item.title}
              onChange={(e) => controller.set('title', e.target.value)}
            />
          </Col>
          <Col span={24}>
            <label>Выберите категории статей</label>
            <Select
              options={__select.list.map((el: any) => ({ label: el.name, value: el.id }))}
              value={controller.item.categories_ids}
              style={{ width: '100%'}}
              mode="multiple"
              onChange={(e: any) => controller.set('categories_ids', e)}
            />
          </Col>
        </Row>
      </form>
    </>
  )
})

export const Calendars = observer(() => {
  useEffect(() => {
    controller.get()
  }, [])

  const buttons = [
    { title: i.t('def:actions.create'), href: `/settings/calendars/0`, type: 'primary' }
  ]

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: i.t('def:name'), dataIndex: 'title', key: 'title' },
    {
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button
            type="dashed"
            shape="circle"
            icon={<EditOutlined />}
            href={`/settings/calendars/${record.id}`}
          />
          <Button
            type="dashed"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            onClick={() => controller.remove(record.id)}
          />
        </Space>
      )
    }
  ]

  return (
    <>
      <Titles
        title={i.t('def:calendars.title')}
        buttons={buttons}
      />

      <Table
        columns={columns}
        dataSource={controller.list}
        pagination={false}
      />
    </>
  )
})